/* .our-partner {
  padding-top: 2%;
  margin-bottom: 8%;
}

.partner-slider {
  max-width: 90%;
  margin: 0 auto;
}

.partner-image {
  width: 100%;

  object-fit: contain;
  box-shadow: 0 0 6px #e9e9e9;
  border-radius: 5px 15px;
  padding: 8px;
  margin-top: 5px;
}
.partner-image img{
  height: 90px;
}*/


.next {
  background-image: url("../../../../public/assets/next.png");
}

.next-image {
  height: 20px;
  width: 20px;
}

.prev-image {
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
} 

 /* .our-partner .slick-list::before {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 175px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.our-partner .slick-list::after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  content: "";
  height: 175px;
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  z-index: 2;
} */