.nes__about .rounded-circle {
  width: 53.6px;
  height: 53.6px;
  background-color: #f8f8f8;
}

.nes__about .card {
  background-color: #fff;
  box-shadow: 0px 4.71221px 20.6323px rgba(0, 0, 0, 0.07);
  border-radius: 18.0038px;
  color: #000;
}
.nes__about .selected-card {
  background-color: #278fd9;
  box-shadow: 0px 4.71221px 20.6323px rgba(0, 0, 0, 0.07);
  border-radius: 18.0038px;
  color: #fff;
}

.nes__about .card h5 {
  font-weight: 400;
  font-size: 20px;
}
@media (max-width: 576px) {
  .nes__about .rounded-circle {
    width: 40px;
    height: 40px;
  }

  .nes__about .card {
    border-radius: 12px;
  }

  .nes__about .card h5 {
    font-size: 10px;
  }
}
