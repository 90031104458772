.accordion__wrapper .accordion-button {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  background-color: transparent;
}

.accordion-button {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
}

.accordion > * {
  border: none !important;
  /* box-shadow: n  one !important; */
}

.accordion-body {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #8ba0ae;
}

.accordion__wrapper .accordion-item {
  box-shadow: 0px 5.18971px 13.8392px rgba(74, 58, 255, 0.19);
  border-radius: 12.1093px;
}

.accordion__wrapper .accordion-item {
  /* border: 1px solid blue !important; */
  background: #ffffff;
  border: 1.7299px solid #278fd9;
  box-shadow: 0px 5.18971px 13.8392px rgba(74, 58, 255, 0.19);
  margin-bottom: 2rem;
}
