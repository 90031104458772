.partner-container {
    /* position: absolute;
    bottom: 2%;
    left: 0;
    right: 0; */
    margin-top: -150px;
    z-index: 1;
}

.partner-search-div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-white);
    border-radius: 10px;
    padding: 10px 0;
    box-shadow: 0px 0px 15px 2px rgba(191, 191, 191, .2);
}
.partner-input-group {
    display: flex;
    align-items: center;
}
.partner-search-icon {
    margin-left: 30px;
    font-size: 18px;
}
.partner-search-input {
    width: 100%;
    background: var(--color-white) !important;
    border: none !important;
    padding: 8px 20px;
}
.partner-search-input:focus,
.partner-search-input:active {
    box-shadow: none;
}
.partner-search-input::placeholder {
    /* font-size: 21px !important; */
    color: var(--gray-color) !important;
    line-height: 1.4;
}

.nes__add__btn {
    width: 35px;
    height: 35px;
    background-color: #278fd9 !important;
    outline: none;
    border: none;
}

.voice-location-icons .rounded-circle {
    width: 32px;
    height: 32px;
    background-color: rgb(39 143 217 / 31%);
    color: var(--primary-color);
}

.dashboard__wrapper__filter select {
    font-weight: 400;
    color: var(--cold-steel-color);
}

/* Set common styles for input placeholders */
.dashboard__wrapper__filter input::placeholder {
    font-weight: 400;
    font-size: 20px;
    color: #b3b4b8;
    opacity: 0.3;
}
.partner-container_search {
    background: var(--primary-color);
    color: var(--color-white);
    padding: 12px 21px;
    margin-left: 14px;
    border-radius: 6px;
}

/* Set styles for specific vendor prefixes */
/* WebKit, Blink, Edge */
input::-webkit-input-placeholder {
    opacity: 0.3;
}

/* Mozilla Firefox 4 to 18 */
input:-moz-placeholder {
    opacity: 0.3;
}

/* Mozilla Firefox 19+ */
input::-moz-placeholder {
    opacity: 0.3;
}

/* Internet Explorer 10-11 */
input:-ms-input-placeholder {
    opacity: 0.3;
}

/* Microsoft Edge */
input::-ms-input-placeholder {
    opacity: 0.3;
}

.voice-location-icons .bg-color-primary {
    padding-top: 13px;
    padding-bottom: 13px;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    margin-left: 0;
}

@media (max-width: 767px) {
    .nes__dashboard__filter {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
    }

    .nes__dashboard__filter div:nth-child(1) {
        grid-column: 1 / -1;
    }

    .nes__dashboard__filter div:nth-child(2) {
        grid-column: start 1 / end -1;
    }
}

@media (max-width: 400px) {
    .nes__dashboard__filter {
        display: flex !important;
        flex-direction: column;
        gap: 1rem;
    }

    .nes__dashboard__filter>div {
        width: 100%;
    }

    .nes__dashboard__filter div:last-child {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}