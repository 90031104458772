.latest-post-left-col {
    margin-bottom: 2rem;
}

.latest-post-left {
    margin-top: 80px;
}

.latest-left_heading {
    font-weight: 900;
    color: var(--primary-color);
    font-size: 30px;
    display: block;
}

.latest-left_para {
    font-weight: 400;
    font-size: 20px;
}


.left-col_box {
    border: 1px solid var(--primary-color);
    border-radius: 14px;
    padding: 10px;
    /* height: 480px; */
}

.left-col_box_child-box {
    overflow: hidden;
    border-radius: 14px;
}

.blogs-user-img {
    width: 60%;
}

.blogs-user-img img {
    width: 20%;
}

.left-col_img {
    width: 100%;
    transition: all .3s ease-in;
    height: 250px;
}

.left-col_img:hover {
    transform: scale(1.04);
}

.left-col_date {
    display: block;
    margin-top: 1rem;
    color: var(--color-white);
    background: var(--primary-color);
    width: fit-content;
    font-weight: 400;
    padding: 6px 30px;
    border-radius: 8px;
    font-size: 14px;
}

.left-col_para {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    padding-top: 10px;
    line-height: 1.4;
    color: var(--cold-black-color);
    transition: all .2s ease-in-out;
}

.left-col_para:hover {
    color: var(--primary-color);
}

.left-col_para-2 {
    font-weight: 600;
    width: 95%;
    font-size: 14px;
    color: var(--silver-surfer);
    line-height: 24px;
}

.left-col_link {
    font-weight: 400;
    display: block;
    color: var(--color-white);
    background: var(--primary-color);
    border: 1px solid transparent;
    width: fit-content;
    padding: 6px 20px;
    border-radius: 8px;
    font-size: 14px;
    transition: all .4s ease-in !important;
}

.left-col_link:hover {
    background: rgb(13 110 253 / 20%) !important;
    color: var(--primary-color) !important;
    border: 1px solid rgb(13 110 253 / 20%) !important;
}

/* .left-col_link:hover {
    color: var(--carnation-color);
} */