.t-c_navbar {
    height: 80px;
    background: #fff !important;
}

.t-c_brand-img {
    width: 100%;
}

.t-c_heading-box {
    background: var(--lite-skyblue);
    padding: 14px 0;
    /* margin-top: 50px; */
}

.t-c_heading {
    font-weight: 600;
    font-size: 24px;
    color: var(--primary-color);
}

.t-c_container {
    margin-bottom: 3%;
    overflow-y: hidden;
}

.t-c_span {
    margin-top: 40px;
    font-weight: 400;
    font-size: 15px;
    display: block;
    line-height: 25px;
    color: var(--silver-surfer);
}

.t-c_footer-box {
    width: 55%;
    font-weight: 400;
    font-size: 16px;
    color: var(--silver-surfer);
    line-height: 30px;
    margin: 30px auto;
    text-align: center;
}
@media(max-width: 998px) {
    .t-c_heading-box {
        /* margin-top: 30px; */
    }
    .t-c_footer-box {
        width: 90%;
    }
}