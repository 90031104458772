.builder-banner-col-3 {
    padding: 30px 20px;
    position: absolute;
    right: 6%;
    background: var(--color-white);
    top: 4%;
    z-index: 10;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(214, 214, 214, 1);
}

.builder-banner-col-3_title_div {
    text-align: center;
}

.builder-banner-col-3_title {
    font-weight: 900;
    font-size: 24px;
}

.builder-banner-col-3_form {
    margin-top: 20px;
    padding: 0 10px;
}

.builder-banner-col-3_input {
    border: 1px solid #E8ECF4;
    width: 100%;
    padding: 10px 20px;
    background: #F8F8F8;
    border-radius: 8px;
}

.builder-banner-col-3_input::placeholder {
    color: var(--silver-surfer) !important;
}

.builder-banner-col-3_input:focus,
.builder-banner-col-3_input:active {
    border: 2px solid #E8ECF4;
    outline: none;
}

.builder-banner-col-3_input:not(:first-child) {
    margin-top: 20px;
}

.builder-banner-col-3_select {
    color: var(--divider) !important;
}

.builder-banner-col-3_btn {
    width: 100%;
    margin-top: 20px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    font-size: 0.9rem;
    font-weight: 400;
    border-radius: 8px;
    color: var(--color-white);
    padding: 10px 0;
}

/* mediaQueri */
@media(max-width: 992px) {
    .builder-banner-col-3 {
        padding: 30px 20px;
        position: absolute;
        right: 6%;
        background: var(--color-white);
        top: 14%;
        z-index: 10;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(214, 214, 214, 1);
    }
}