.how-container {
  margin: 0px auto;
  /* padding: 30px 0; */
  overflow: hidden;
}

.how-box {
  text-align: center;
}

.how-work_img {
  width: 40%;
  margin: auto;
}

.how-heading-2 {
  font-weight: 900;
  font-size: 36px;
  display: block;
}

.how-container .how-col-1 {
  /* padding-top: 2%; */
}

.how-col-1-span-1 {
  font-weight: 600;
  font-size: 26px;
  width: 70%;
  margin-left: auto;
  display: block;
  color: var(--cold-black-color);
}

.how-col-1-span-2 {
  width: 70%;
  margin-left: auto;
  font-weight: 400;
  font-size: 15px;
  display: block;
  line-height: 25px;
  color: var(--silver-surfer);
}

.how-container .how-col-2 {
  text-align: center;
}

.how-col-2-span-1 {
  font-size: 2rem;
  font-weight: 600;
  padding: 5px;
}

.how-container .how-container-col-3 {
  text-align: center;
}

/* mind boggling lines
====================*/
.line-div {
  text-align: right;
  z-index: -1;
}

.line-1 {
  height: 18px;
  display: block;
  margin-left: 52%;
  border-left: 2px solid var(--silver-surfer);
}

.line-2 {
  height: 30px;
  display: block;
  margin-left: 52%;
  border-left: 2px solid var(--silver-surfer);
  margin-top: 25px;
}

.line-3 {
  height: 18px;
  display: block;
  margin-left: 52%;
  border-left: 2px solid var(--silver-surfer);
  margin-top: 25px;
}

.radius {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 54%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.place {
  background: #dbeefb;
  z-index: 2;
}

.radius .border-animation,
.place {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
}

.radius .border-animation {
  z-index: 1;
}

.ba1 {
  animation: rounder1 2s linear 2s infinite alternate;
  background: rgb(234 244 251 / 60%);
}

.how-work_img-small {
  width: 30%;
}

@keyframes rounder1 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.3);
  }
}

.ba2 {
  animation: rounder2 2s linear 2s infinite alternate;
  background: rgb(234 244 251 / 50%);
}

@keyframes rounder2 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.6);
  }
}

.ba3 {
  animation: rounder3 2s linear 2s infinite alternate;
  background: rgb(234 244 251 / 40%);
}

@keyframes rounder3 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.9);
  }
}

/* media query */
@media (max-width: 768px) {
  .how-container .px-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .how-col-1-span-1 {
    width: 100%;
    padding-top: 5%;
    line-height: 28px;
    font-size: 21px;
  }

  .how-col-1-span-2 {
    width: 100%;
    font-size: 12px;
    line-height: 1.6;
    padding-top: 2%;
  }

  .how-col-2-span-1 {
    font-size: 2rem;
  }

  .how-container-col-3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .how-work_img {
    width: 100%;
    margin: auto;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .how-col-1-span-1 {
    width: 100%;
    padding-top: 5%;
    line-height: 38px;
    font-size: 24px;
  }

  .how-col-1-span-2 {
    width: 100%;
    font-size: 14px;
    padding-top: 2%;
  }

  .how-col-2-span-1 {
    font-size: 2rem;
  }

  .how-container-col-3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .how-work_img {
    width: 60%;
    margin: auto;
  }
}

@media (max-width: 1300px) and (min-width: 993px) {
  .how-col-1-span-1 {
    width: 100%;
  }

  .how-col-1-span-2 {
    width: 100%;
  }
}