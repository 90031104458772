.partners-container .partners-fifth__card {
    padding: 10px;
    border: 2px var(--wild-sand) solid;
    margin-top: 30px;
}
.partners-container .fifth-card__box  {
    background: var(--lite-skyblue);
    padding: 8% 5%;
    margin: auto;
    width: 100%;
}
.partners-container .fifth-card__span-1 {
    text-align: center;
    margin: auto;
    font-size: 1.35rem;
    color: var(--cold-black-color);
    font-weight: 600;
}
.partners-container .fifth-card__box-2 {
    text-align: center;
    padding: 30px;
    width: 100%;
}
.partners-container .fifth-card__span-2 {
    font-size: 1.35rem;
    display: block;
    margin-bottom: 20px;
    font-weight: 500;
}
.partners-container .fifth-card__link {
    font-size: 0.9rem;
}
.partners-container .fifth-card__link-span {
    color: var(--color-white);
    background: var(--carnation-color);
    padding: 10px 30px;
}
.partners-container .fifth-card__box-3 {
    padding: 25px 0 0 25px;
}
.partners-container .fifth-card__span-3 {
    font-size: 20px;
    font-weight: 700;
    color: var(--cold-black-color);
}
.partners-container .fifth-card__li {
    font-size: 16px;
    line-height: 1.4;
    list-style: none;
    font-weight: 400;
    color: var(--silver-surfer);
}
.partners-container .fifth-card__dot {
    width: 5px;
}