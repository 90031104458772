.LatestPostPagination-pagination-ul {
    /* margin-top: 30px; */
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LatestPostPagination-pagination-li {

    list-style: none;
    background: transparent;
    font-size: 14px;
    border: 1px solid #eaeaea;
    font-weight: 400;
    padding: 10px 18px 5px 18px;
    border-radius: 2px;
    cursor: pointer;
}

.LatestPostPagination-pagination-li:hover,
.LatestPostPagination-pagination-li:focus {
    background: rgb(13 110 253 / 20%) !important;
    color: var(--primary-color) !important;
    border: 1px solid rgb(13 110 253 / 20%) !important;
}

.LatestPostPagination-pagination-li-active {
    color: var(--color-white);
    background: var(--primary-color);
    border-radius: 2px;
}

.LatestPostPagination-pagination-li-arrow {
    list-style: none;
    font-size: 22px;
    cursor: pointer;
    margin: 0 14px;
}