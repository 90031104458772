.BrokerPDabout {
    margin-top: 45px;
    padding: 0;
}

.BrokerPDabout-col {
    padding: 20px 0;
    border-radius: 14px;
    box-shadow: 4px 10px 30px 1px rgba(0, 0, 0, 0.055);
}

.BrokerPDabout-col_div {
    display: flex;
    padding: 0 30px;
    justify-content: space-between;
    align-items: center;
}

.BrokerPDabout-col_span_1 {
    font-size: 21px;
}

.BrokerPDabout-col_link,
.BrokerPDabout-col_link:hover {
    font-weight: 800;
    text-decoration: underline !important;
    color: var(--carnation-color);
}

.BrokerPDabout-col_div-2 {
    padding: 0 30px;
}

.BrokerPDabout-col_div-2_span {
    display: block;
    font-size: 14px;
    line-height: 24px;
    font-weight: 350;
    line-height: 26px;
    color: var(--silver-surfer);
}

/* Media Queri ===================== */
@media(max-width: 576px) {
    .BrokerPDabout {
        padding: 0 10px;
    }
}