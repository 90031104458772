.brokerage__management__card {
  background-color: #ffffff;
  box-shadow: 0px 5.25363px 22.9846px rgba(0, 0, 0, 0.07);
  border-radius: 19.7363px;
}

.brokerage__management__card button {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.brokerage__management__card .raise {
  background-color: #ffe4d4;
  border: 1.72817px solid #d64d00;
}

.brokerage__management__card .raise:hover {
  color: #000000;
}

.brokerage__management__card .custom-green-btn {
  background-color: #eaf5e7;
  border: 1.72817px solid #88c879;
}

.brokerage__management__card .custom-green-btn:hover {
  color: #000000;
}

.brokerage__management__card .claim-submitted {
  background-color: #ffe4d4;
  border: 1.72817px solid #d64d00;
}

.brokerage__management__card .claim-submitted:hover {
  color: #000000;
}

.brokerage__management__card .claim-approved {
  background-color: #e2f3ff;
  border: 1.72817px solid #278fd9;
}

.brokerage__management__card .claim-approved:hover {
  color: #000000;
}
