.partners-container .partners-container_span-2 {
    font-size: 24px;
    display: block;
    font-weight: 900;
}
.partners-container .partners-img-3 {
    height: 50%;
}
.partners-container .partners-container_star {
    color: #ffb630;
    font-size: 21px;
    margin-right: 3px;
}
.partners-container_span-4 {
    font-size: 16px;
    font-weight: 400;
}