.partner-card {
    margin: 40px auto 55px auto;
}

.partner-card-div {
    border-radius: 10px;
    box-shadow: 0px 0px 15px 2px rgba(191, 191, 191, .2);
    text-align: center;
    padding: 40px 0;
    margin: 0 2% 10% 2%;
    transform: scale(1);
    transition: all .3s ease-in;
}
.partner-card-div:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 15px 2px rgb(39 143 217 / 20%);
}

.partner-card-heading {
    font-weight: 700;
    font-size: 20px;
    display: block;
    margin-top: 12px;
    color: #282431;
}

.partner-card-span {
    font-weight: 400;
    font-size: 16px;
    color: var(--primary-color);
    display: block;
}

.partner-star-div {
    margin-top: 8px;
}

.partner-card-img-div{
    border-radius: 50%;
    border: 1px solid #e6e6e6;
    width: 145px;
    max-width: 100%;
    height: 145px;
    padding: 5px;
    object-fit: scale-down;
}