.login-as-broker {
    padding: 10px 0 20px 0;
    text-align: center;
}

.login-as-broker-div {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    padding: 10px 0;
}
.login-as-broker-img {
    width: 34px;
}
.login-as-broker-text {
    color: var(--silver-surfer);
    font-weight: 400;
    font-size: 0.9rem;
    margin-left: 10px;
}

.login-as-broker-input-div {
    margin-top: 20px;
    text-align: left;
}

.login-as-broker-label {
    font-size: 0.9rem;
    margin-bottom: .2rem;
    color: var(--silver-surfer);
    font-weight: 400;
}
.login-as-broker-input {
    border: 2px solid #E8ECF4;
    background: #F8F8F8;
    width: 100%;
    font-size: 0.9rem;
    padding: 10px 20px;
    border-radius: 8px !important;
}

.login-as-broker-input:active,
.login-as-broker-input:focus {
    outline: none;
    box-shadow: none;
}

.login-as-broker-input::placeholder {
    color: var(--cold-black-color) !important;
}

.login-as-broker-btn {
    width: 100%;
    padding: 12px 0;
    font-size: 18px;
    font-weight: 400;
    margin-top: 30px;
    background: var(--primary-color);
    color: var(--color-white) !important;
    border-radius: 10rem !important;
    border: 1px solid var(--primary-color);
}