.swiper {
  padding: 10px 10px 10px 10px !important;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-weight: 600;
  font-size: 10px;
  color: #000000;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#ffb630 0%, #ffb630 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon span::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #ffb630;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ffb630;
}
.ribbon span::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #ffb630;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ffb630;
}

.swiper__card__row:first-child span {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #7d7f88;
}

.swiper__card__row:nth-child(2) span {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.03em;
  color: #000000;
}

/*  */
.swiper__card__row:nth-child(3) span {
  font-weight: 400;
  font-size: 12px;
  color: #278fd9;
}

/* swiper title */
.swiper-title {
  color: #282431;
  font-size: 18px;
}

.swiper-title {
  font-size: 20px !important;
  font-weight: bold !important;
}

.swiper-subtitle {
  font-weight: 600;
  font-size: 14px;
  color: #7d7f88;
}

.swiper__price {
  text-align: right !important;
}
