.coose-container {
    /* background: #000; */
    padding: 0;
}
.choose-row {
    width: 96% !important;
    margin: auto;
    /* background: #777; */
    /* margin-bottom: 6%; */
}

.choose-col {
    padding: 16px;
    text-align: center;
}

.choose-card:hover {
    transform: scale(1.05);
    border: 1px solid rgb(39 143 217 / 20%);
    box-shadow: 0px 0px 15px 2px rgb(39 143 217 / 20%);
    /* border: 1px solid var(--primary-color); */
}
.next-image {
    /* width: 20px !important; */
}

.choose-card {
    border-radius: 10px;
    box-shadow: 0px 0px 15px 2px rgba(191, 191, 191, .2);
    border: 1px solid transparent;
    padding: 0 15px 30px 15px;
    transform: scale(1);
    cursor: pointer;
    transition: all .3s ease-in-out;
    margin-bottom: 40px;
    height: 330px;
}


.choose-icon-div {
    width: 120px;
    height: 120px;
    margin: auto;
    background: transparent;
    border-radius: 50%;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
}

.choose-card:hover .choose-icon-div {
    background: var(--lite-skyblue);
}

.choose-icon-img {
    margin: 30px auto 16px auto;
}

.card-heading {
    color: var(--cold-black-color);
    font-weight: 900;
    font-size: 17px;
}

.card-desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: var(--silver-surfer);
    display: block;
    padding-top: 6px !important;
}


@media(max-width: 992px) {
    .choose-row {
        width: 90% !important;
    }
}