.claim-hero-section {
  position: relative;
}

.claim-hero-image {
  background-size: cover;
  background-position: center;
  height: 450px;
}

.claim-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-box {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
}

.claim__section__card {
  background-color: #ffffff;
  box-shadow: 0px 6.52464px 36.8739px rgba(0, 0, 0, 0.07);
  border-radius: 14.6804px;
}
