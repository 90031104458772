.total-revenue {
    margin-top: 25px;
}

.total-revenue-div {
    box-shadow: 0px 0px 15px 0px rgba(237, 237, 237, 1);
    /* border: 1px solid red; */
    border-radius: 12px;
    padding: 16px;
}

.total-revenue-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total-revenue-top-bar-heading {
    font-weight: 400;
    font-size: 18px;
}

.total-revenue-top-bar-option {
    border-bottom: 1px solid var(--divider);
    /* padding-bottom: 3px; */
}

.total-revenue-top-bar-option-1 {
    padding-bottom: 4px;
    cursor: pointer;
    font-size: 12px;
    color: var(--silver-surfer);
    /* border-bottom: 4px solid var(--primary-color); */
}

.total-revenue-top-bar-option-2 {
    padding-bottom: 4px;
    /* border-bottom: 4px solid var(--primary-color); */
    color: var(--silver-surfer);
    cursor: pointer;
    font-size: 12px;
    margin-left: 40px;
}

.total-revenue-top-bar-option-3 {
    cursor: pointer;
    padding-bottom: 4px;
    border-bottom: 4px solid var(--primary-color);
    color: var(--silver-surfer);
    font-size: 12px;
    margin-left: 30px;
}

.total-revenue-price-div {
    display: flex;
    align-items: center;
    margin-top: 21px;
}

.total-revenue-price {
    font-weight: 700;
    font-size: 32px;
}
.total-revenue-price-div-2 {
    display: flex;
    align-items: center;
}
.total-revenue-price-div-2-icon {
    font-size: 21px;
    color: var(--primary-color);
}
.total-revenue-price-div-2-span-1 {
    display: block;
    font-size: 18px;
    color: var(--primary-color);
}
.total-revenue-price-div-2-span-2 {
    font-size: 14px;
    font-weight: 400;
    color: var(--silver-surfer);
}