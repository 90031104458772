.navbar-brand img {
  width: 185px;
}

.profile-img {
  width: 40px;
  height: 40px;
}

.nes__dashboard__navbar .dropdown-menu > .dropdown-item:first-child {
  margin-bottom: 1rem;
}

.nes__dashboard__navbar .dropdown-menu > .dropdown-item {
  display: flex;
  flex-direction: column;
  padding: 0.3rem 1rem !important;
}

.nes__dashboard__navbar .dropdown-menu > .notification {
  padding: 0.3rem 0.7rem !important;
}

@media (max-width: 992px) {
  .nes__dashboard__navbar .dropdown-menu > .dropdown-item,
  .nes__dashboard__navbar .dropdown-menu > .notification {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
}
