.profile__form input {
  border: none !important;
  background: #f8f8f8;
}
.main-heading {
  font-family: Bahnschrift;
  font-size: 18.77px;
  font-weight: 400;
  line-height: 22.52px;
  text-align: left;
  color: black;
}
.construction-status {
  font-size: 0.7rem !important ;
  padding: 8px 0 !important;
  box-shadow: none !important;
  text-transform: none !important;
}
