.nes__product__section__title {
  font-weight: 400;
  font-size: 30px;
  color: #000000;
}

.nes__product__section__view {
  font-weight: 700;
  font-size: 15.8296px;
  color: #fc5c67;
}
