.not-register {
    text-align: center;
    padding: 30px 0;
}

.not-register-img {
    width: 50%;
    margin-top: -3rem;
}

.not-register-heading {
    font-weight: 700;
    font-size: 30px;
    display: block;
}

.not-register-sub-heading {
    font-size: 1rem;
    color: var(--silver-surfer);
    width: 80%;
    line-height: 1.6;
    display: block;
    margin: auto;
}

.not-register-btn {
    width: 100%;
    padding: 10px 0;
    font-size: 1rem;
    font-weight: 400;
    margin-top: 10px;
    background: var(--primary-color);
    color: var(--color-white) !important;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
}