.nes__product__card .card-img-top {
  border-radius: 14px !important;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.nes__product .card {
  border-radius: 13.9444px !important;
}

.nes__product__card__btndark {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 2.99764px 13.1251px rgba(0, 0, 0, 0.07);
  border-radius: 7.20386px;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  position: absolute;
  bottom: 7px;
  left: 7px;
  z-index: 99999;
}

.nes__product__card__title {
  font-weight: 700;
  font-size: 18.03px;
  letter-spacing: 0.03em;
  color: #252b5c;
}

.nes__product__card__subtitle {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #7d7f88;
}

.nes__product__card__icons {
  font-weight: 400;
  font-size: 12px;
  color: #939197;
}

.nes__product__card__price h4 {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.nes__product__card__price span {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.01em;
  color: #7d7f88;
}

.swiper-pagination-bullet-active {
  background-color: #fe5e6a; /* replace with your desired color */
}

.nes__product__card .swiper {
  margin-bottom: 0px !important;
}
