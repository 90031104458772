.timeline {
  position: relative;
}

.dotted-line {
  position: relative;
  width: 2px;
  height: 30px;
  border-left: 1px dashed black;
  margin: 0 auto;
}

.dotted-line > svg {
  position: absolute;
  bottom: -10px; /* Adjust the distance of the arrow from the dotted line */
  left: 50%;
  transform: translateX(-55%);
}
.tav {
  border: 1px solid red !important;
  border-radius: 20px !important;
  padding: 20px !important;
  width: 20%;
}
