.property__details__header__imgfull {
  /* height: 370px; */
  border-radius: 13.9239px;
}

.nes__dashboard__images__small1,
.nes__dashboard__images__small2 {
  height: 50%;
}

.nes__dashboard__images__small1 img,
.nes__dashboard__images__small2 img {
  height: 100%;
}

.property__details__header .card-img-overlay {
  /* background-color: rgb(0 0 0 / 28%); */
  background: linear-gradient(
    355deg,
    #000000 1.24%,
    rgba(0, 0, 0, 0.4) 14.73%,
    rgba(217, 217, 217, 0) 99.79%
  );
  border-radius: 13.9239px;
}

.property__details__profileimg {
  width: 95px;
  height: 95px;
}

.property__details__header .card-img-overlay .card-title {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}

.property__details__header .card-img-overlay .card-text {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}
