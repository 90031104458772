.video-main-div {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 4%;
}

.home-video {
  width: 100%;
  height: 100%;
  /* object-fit: fill;
  object-position: center; */
}

@media (max-width: 680px) {
  .video-main-div {
    margin-bottom: 8%;
  }
  .home-video {
    width: 100%;
    height: 220px;
    object-fit: fill;
    object-position: center;
  }
}