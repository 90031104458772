.partners-second__card {
    padding: 10px;
    margin-top: 20px;
    border: 2px var(--wild-sand) solid;
}
.second-card__box  {
    background: var(--lite-skyblue);
    padding: 14% 5%;
    margin: auto;
}
.second-card__span-1 {
    text-align: center;
    margin: auto;
    font-size: 1.25rem;
    color: var(--cold-black-color);
    font-weight: 600;
}
.second-card__btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.second-card__btn {
    width: 45%;
    margin: auto;
    margin-top: 50px;
    background: #000;
    text-decoration: none;
    padding: 8px 0 10px 0;
    padding-left: 6px;
}
.second-card__icon {
    width: 140px;
}
.second-card__icon-span-1 {
    font-size: 10px;
    display: block;
    line-height: 15px;
    color: var(--color-white);
    font-weight: lighter;

}
.second-card__icon-span-2 {
    font-size: 18px;
    line-height: 15px;
    display: block;
    color: var(--color-white);
}
.second-card__box-2 {
    width: 100%;
    margin-top: 50px;
}
.second-card__img-1 {
    width: 100%;
}