.profile__main__title {
  font-weight: 700;
  font-size: 26px;
  letter-spacing: 0.03em;
  color: #000000;
}

.profile__main .card-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
  color: #7d7f88;
}
