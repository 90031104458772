.blog-list-banner {
    background: linear-gradient(rgba(0,0,0,.55), rgba(0,0,0,.45)),
    url(../../../public/assets/informative/blogListBanner.png);
    width: 100%;
    height: 310px;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-list-banner-heading {
    font-weight: 700;
    font-size: 59px;
    color: var(--color-white);
}