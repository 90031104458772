.login__header.nes__header {
    z-index: 100;
    background: var(--color-white) !important;
    padding: 0 0 0 6%;
    box-shadow: 1px 0px 3px #d8d8d8;
}

.info-login__header__img {
    width: 170px;
}

.login-link {
    color: var(--primary-color);
    font-size: 14px;
    border: 1px solid var(--primary-color);
    font-weight: 400;
    border-radius: 8px;
    background: var(--color-white);
    transition: all .3s ease-in;
    padding: 7.15px 30px;
}

.login-link:hover {
    background: var(--primary-color);
    color: var(--color-white);
}

.broker-link-div {
    display: flex;
    align-items: center;
}

.informative-navbar-broker-link {
    margin-left: 6px;
}

.list-property-link {
    color: var(--color-white);
    font-size: 14px;
    border: 1px solid var(--primary-color);
    padding: 10px 30px;
    font-weight: 400;
    border-radius: 8px;
    background: var(--primary-color);
    transition: all .3s ease-in;
}

.list-property-link:hover {
    background: var(--color-white);
    color: var(--primary-color);
}

.nes__header-download-button,
.nes__header-download-button:hover {
    background-color: var(--carnation-color);
    border: none;
    color: var(--color-white);
    padding: 20px 45px 20px 30px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

/* Navbar Collapse Styling ======================== 
===============================================  */
.navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    transition: .5s ease-in-out;
    border: 1px solid var(--primary-color);
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
}

.navbar-toggler span {
    margin: 0;
    padding: 0;
}

.toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: var(--carnation-color);
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.toggler-icon-middle-bar {
    margin-top: 0px;
    height: 2px;
}

/* when navigation is clicked */

.navbar-toggler .toggler-icon-top-bar {
    margin-top: 0px;
    transform: rotate(135deg);
}

.navbar-toggler .toggler-icon-middle-bar {
    opacity: 0;
    filter: alpha(opacity=0);
}

.navbar-toggler .toggler-icon-bottom-bar {
    margin-top: 0px;
    transform: rotate(-135deg);
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .toggler-icon-top-bar {
    margin-top: -10px;
    transform: rotate(0deg);
}

.navbar-toggler.collapsed .toggler-icon-middle-bar {
    opacity: 1;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .toggler-icon-bottom-bar {
    margin-top: 10px;
    transform: rotate(0deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
    background: linear-gradient(263deg, var(--carnation-color) 0%, var(--primary-color) 100%);
    background: var(--primary-color);
}

/* Media Queri ================= 
============================  */
@media (max-width: 992px) {
    .login__header.nes__header {
        padding: 2% 3%;
    }

    .nes__header-collapse-nav {
        align-items: start !important;
        padding: 4% 0 0 0;
    }

    .broker-link-div {
        margin: 3% 0 6% 0;
    }

    .list-property-link {
        margin-top: 3%;
    }

    .nes__header-download-button {
        margin-top: 5%;
        padding: 14px 30px 14px 10px;
        border-radius: 8px;
    }

    .basic-navbar-nav {
        border-top: 1px solid #E0E0E0;
        margin-top: 2.4%;
    }
}