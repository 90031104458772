*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
  position: relative;
}

[data-link] {
  cursor: pointer;
}

[data-bg] {
  background-position: center center;
  background-size: cover;
}

.fx {
  margin-top: 0 !important;
}

.js-color-bg {
  fill: #000;
}

[data-cursor] {
  mix-blend-mode: difference;
  display: none;
  pointer-events: none;
  width: 0;
  height: 0;
  margin-top: 2px;
  margin-left: 2px;
  z-index: 99999999;
  position: absolute;
  top: 0;
  left: 0;
}

html:hover [data-cursor] {
  display: block;
}

html.ie [data-cursor],
html.mob [data-cursor] {
  display: none !important;
}

[data-cursor] .cur1 {
  width: 5px;
  height: 5px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  z-index: 5;
}

[data-cursor] .cur1>* {
  opacity: 0;
  transition: 0.4s;

  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  text-align: center;
}

[data-cursor] .cur2 {
  transition: opacity 0.4s, transform 0.4s;
  opacity: 0.3;
  width: 15px;
  height: 15px;
  background-color: #fff;
  /* margin: -11px 0 0 -10px; */
  border-radius: 50%;
}

.drag [data-cursor] .cur2,
.hover [data-cursor] .cur2 {
  transform: scale(4);
  opacity: 0.1;
}

.gsap-scroll-trigger {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#sc .h1 {
  /* margin-top: calc(25px + 10 * (100vw - 320px) / 1080); */
  /* margin-bottom: -10px; */
  text-transform: uppercase;
  font-size: calc(12px + 1 * (100vw - 320px) / 1080);
  letter-spacing: calc(2px + 1 * (100vw - 320px) / 1080);
}

#sc .h1 h1 {
  font-weight: 300;
}

.cirbc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.cirbc .cirb,
.cirbc .cirbr {
  width: 150vw;
  height: 150vw;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cirbc .cirbr {
  background-color: #061a2e;
  width: 0;
  height: 0;
}

.cirbc svg {
  fill: none;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
  opacity: 0;
  width: 100px;
  fill: transparent;
  stroke-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.cirbc .ico {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #fff;
}

.cirbc .ico i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.sp {
  height: 0px !important;
  pointer-events: none;
}

.sp-3 {
  height: 0px !important;
}

#e {
  color: #fff;
  text-align: left;
  /* padding-top: 50vh; */
  pointer-events: none;
  height: 0px !important;
}

#e h2 span {
  display: block;
}

.eavl {
  color: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  text-align: right;
}

.eavl h2 {
  font-size: calc(25px + 15 * (100vw - 320px) / 1080);
  font-weight: 200;
  width: 50%;
  left: calc(-80px - 30 * (100vw - 320px) / 1080);
}

.eavl h2 span {
  display: block;
}

.eavl h2 strong {
  font-weight: 800;
}

#sv {
  min-height: 100vh;
}

#sv .rg {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  padding-left: 65%;
}

#sv .cir {
  width: 76vh;
  height: 76vh;
  border-radius: 50%;
  top: 12vh;
}

#sv .circ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #cdcccf;
}

/*  */
#sv .circm {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
}

#sv .rnd {
  width: 1px;
  height: 1px;
  position: absolute;
}

#sv .rnd>div {
  width: calc(120px + 50 * (100vw - 320px) / 1080);
  height: calc(120px + 50 * (100vw - 320px) / 1080);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sv .rnd>div>div {
  transform-origin: center center;
  box-shadow: 0 1px 3px 1px rgba(99, 0, 243, 0.2);
  width: 5%;
  height: 5%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sv .rnd i {
  font-size: calc(50px + 30 * (100vw - 320px) / 1080);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sv .crc {
  position: absolute;
}

#sv .crc>div {
  position: absolute;
  width: 100%;
  height: 100%;
}

#sv .crc:nth-child(1) {
  height: 1px;
  width: 38vh;
  right: 0;
}

#sv .crc:nth-child(1)>div {
  right: 0;
}

#sv .crc:nth-child(1) .rnd {
  left: 0;
}

#sv .crc:nth-child(2) {
  height: 38vh;
  width: 1px;
  bottom: 0;
}

#sv .crc:nth-child(2)>div {
  bottom: 0;
}

#sv .crc:nth-child(2) .rnd {
  top: 0;
}

#sv .crc:nth-child(3) {
  height: 1px;
  width: 38vh;
  left: 0;
}

#sv .crc:nth-child(3)>div {
  left: 0;
}

#sv .crc:nth-child(3) .rnd {
  right: 0;
}

#sv .crc:nth-child(4) {
  height: 38vh;
  width: 1px;
  top: 0;
}

#sv .crc:nth-child(4)>div {
  top: 0;
}

#sv .crc:nth-child(4) .rnd {
  bottom: 0;
}

#sv .lgt {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 50%;
  padding-left: 15%;
}

#sv .lgt h3 {
  font-weight: 200;
  font-size: calc(18px + 10 * (100vw - 320px) / 1080);
  line-height: 1.3;
}

#sv .lgt h3 strong {
  font-weight: 800;
  display: block;
  font-size: 26px;
}

#sv .lgt .tx {
  font-size: calc(13px + 2 * (100vw - 320px) / 1080);
  width: 80%;
  line-height: 1.8;
  /* margin: calc(20px + 5%) 0; */
  color: #666;
  letter-spacing: 0.25px;
}

.sp-5 {
  position: absolute;
  top: 0;
}

.lgtxc {
  text-align: left;
  height: 80vh;
  min-height: 600px;
  max-height: 950px;
  display: flex;
  align-items: center;
}

.lgtxc .ct>div {
  width: calc(50% - 100px);
  left: calc(50% + 100px);
}

.lgtxc h3 {
  font-size: 18px;
  font-weight: 200;
}

.lgtxc h3>* {
  display: block;
}

.lgtxc h3>strong {
  font-weight: 800;
}

.lgtxc .txt {
  font-size: calc(12px + 2 * (100vw - 320px) / 1080);
  width: 100%;
  font-weight: 200;
  line-height: 1.8;
  /* margin: calc(25px + 2%) auto 0; */
  color: #fff;
}

.lgtxc .txt strong {
  font-weight: 800;
}

/* circular roation section end */

@media screen and (max-width: 1250px) {
  #sv .rg {
    padding-left: 75%;
  }

  #sv .lgt {
    padding-right: 35%;
  }
}

@media screen and (min-width: 901px) {
  #sv .rg>div {
    transform: none !important;
  }
}

@media screen and (max-width: 950px) {
  #sv .rg {
    padding-left: 0 !important;
    left: 0;
  }

  .animate-cartoon {
    display: none;
  }

  #sv .rg>div {
    transform: translateY(-70%) rotate(-90deg);
    transform-origin: center center;
  }

  #sv .rg .cir {
    top: 0;
    width: 100vw;
    height: 100vw;
  }

  #sv .rg .rnd {
    transform: rotate(90deg);
  }

  #sv .lgt {
    padding: 40vh 0 0 !important;
    text-align: center;
    padding-right: 16px !important;
  }

  #sv .lgt .tx {
    width: 100%;
    padding: 0 calc(20px + 5%);
    /* margin: calc(25px + 15 * (100vw - 320px) / 1080) 0; */
  }

  #sv .crc:nth-child(1),
  #sv .crc:nth-child(3) {
    width: 50vw;
  }

  #sv .crc:nth-child(2),
  #sv .crc:nth-child(4) {
    height: 50vw;
  }

  .eavl {
    text-align: center;
    /* margin-top: 0 !important; */
    position: relative;
    align-items: flex-end;
  }

  .eavl h2 {
    display: none;
    transform: none !important;
    opacity: 1 !important;
    width: 100%;
    top: 100vh;
    left: 0;
  }

  .eavl .ct {
    width: 100%;
    text-align: center;
  }

  .lgtxc .ct>div {
    width: 100%;
    left: 0;
    text-align: left;
  }

  .cirbc .ico,
  .cirbc svg {
    top: 30%;
  }
}

.scroll-circle h3 {
  font-family: 'Bahnschrift';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  /* identical to box height, or 68% */

  color: #282431;
}

.scroll-circle img {
  width: min(100%, 393px);
}

#app {
  overflow-x: hidden;
}

.scroll-content {
  transform: inherit;
}

.leadNurt {
  background-color: #1371e2;
}

/* tst code */
*,
:after,
:before {
  -webkit-tap-highlight-color: transparent;
  list-style-position: inside;
  list-style-type: none;
  color: inherit;
  box-sizing: border-box;
  margin: 0;

  padding: 0;
  outline: 0;
  position: relative;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  /* font-family: Poppins !important; */
}

[data-cursor] {
  mix-blend-mode: difference;
  display: none;
  pointer-events: none;
  width: 0;
  height: 0;
  /* margin-top: 2px; */
  /* margin-left: 2px; */
  z-index: 99999999;
  position: absolute;
  top: 0;
  left: 0;
}

html:hover [data-cursor] {
  display: block;
}

html.ie [data-cursor],
html.mob [data-cursor] {
  display: none !important;
}

[data-cursor] .cur1 {
  width: 5px;
  height: 5px;
  /* margin: -4px 0 0 -4px; */
  border-radius: 50%;
  z-index: 5;
}

[data-cursor] .cur1>* {
  opacity: 0;
  transition: 0.4s;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  text-align: center;
}

[data-cursor] .cur2 {
  transition: opacity 0.4s, transform 0.4s;
  opacity: 0.3;
  width: 15px;
  height: 15px;
  background-color: #fff;
  margin: -11px 0 0 -10px;
  border-radius: 50%;
}

* {
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}

.scrollbar-track {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s, background 0.3s;
  position: absolute;
  z-index: 9999;
  background-color: #eee;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scrollbar-track.show,
.scrollbar-track:hover {
  opacity: 1;
}

.landh .scrollbar-track,
.scrollbar-track.show {
  opacity: 1;
}

.scrollbar-track-y {
  height: 100%;
  width: 3px;
  top: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.1);
}

.scrollbar-track-y .scrollbar-thumb {
  cursor: pointer;
  pointer-events: auto;
  width: 8px;
  left: -2px;
  border-radius: 5px 0 0 5px;
}

.scrollbar-track-y .scrollbar-thumb:before {
  content: '';
  width: 4px;
  height: 100%;
  left: 1px;
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px 0 0 5px;
}

.landh .scrollbar-track-y .scrollbar-thumb:before {
  background: #061a2e !important;
  border-radius: 5px 0 0 5px;
}

#ck {
  overflow: hidden;
  background-color: #008aff;
  color: #fff;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  transition: transform 0.6s, opacity 0.6s, visibility 0.6s;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
  max-width: calc(99vw - 20px);
  position: fixed;
  bottom: calc(10px + 0.5vw);
  left: calc(10px + 0.5vw);
  z-index: 9990;
  width: 400px;
  line-height: 1.3;
}

.cks.fix #ck {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

#ck p strong {
  display: block;
  /* margin-bottom: 10px; */

  line-height: 1;
}

#ck a {
  text-decoration: underline;
}

#ck a:hover {
  color: #222;
}

#ck i {
  /* margin-right: 15px; */
  top: -8px;
}

#ck i:before {
  animation: cookies 1s infinite linear;
}

@keyframes arrow {
  0% {
    margin-left: -5px;
  }

  50% {
    margin-left: 5px;
  }

  100% {
    margin-left: -5px;
  }
}

@keyframes arrowd {
  0% {
    margin-top: -5px;
  }

  50% {
    margin-top: 5px;
  }

  100% {
    margin-top: -5px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}

.scroll {
  position: absolute;
  bottom: calc(25px + 2%);
  left: calc(15px + 2%);
  height: calc(40px + 15 * (100vw - 320px) / 1080);
}

.scroll>div {
  width: 3px;
  background-color: #fff;
  height: 100%;
}

.scroll>div:after,
.scroll>div:before {
  content: '';
  bottom: -2px;
  position: absolute;
  background: inherit;
  height: 25%;
  width: inherit;
}

.scroll>div:before {
  transform: rotate(-40deg);
  transform-origin: bottom left;
  border-bottom-left-radius: 3px;
}

.scroll>div:after {
  transform: rotate(40deg);
  transform-origin: bottom right;
  border-bottom-right-radius: 3px;
}

#app {
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

html:not(.horiz) #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 3;
}

html:not(.horiz) #app>.scroll-content {
  width: 100%;
  height: 100%;
  z-index: 5;
}

html:not(.horiz) #app main {
  width: 100%;
  z-index: 6000;
}

html:not(.horiz) #app .app-c {
  overflow: hidden;
}

.cirbc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.cirbc .cirb,
.cirbc .cirbr {
  width: 150vw;
  height: 150vw;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cirbc .cirbr {
  background-color: #061a2e;
  width: 0;
  height: 0;
}

.cirbc svg {
  fill: none;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
  opacity: 0;
  width: 100px;
  fill: transparent;
  stroke-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.cirbc .ico {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  color: #fff;
}

.cirbc .ico i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.sp {
  height: 100vh;
  pointer-events: none;
}

.sp-3 {
  height: 30vh;
}

#e {
  color: #fff;
  text-align: left;
  padding-top: 5vh;
  pointer-events: none;
}

#e h2 span {
  display: block;
}

.eavl {
  color: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  text-align: right;
}

.eavl>* {
  flex: 1;
  margin: auto;
}

.eavl h2 {
  width: 50%;
  left: calc(-80px - 30 * (100vw - 320px) / 1080);
}

.eavl h2 span {
  display: block;
}

.eavl h2 strong {
  font-weight: 800;
}

html:not(.creaOpen) .fix:not(.onav) #h:not(:hover) .logo,
.signIN {
  width: 100%;
}

body:not(.onav) #h.blck-1 .logo svg,
body[class*='blck-']:not(.onav) #h .logo svg {
  fill: #061a2e;
}

body[class*='whte-']:not(.onav) #h .logo svg {
  fill: #fff !important;
}

body:not(.onav) #h.blck-1 .bn>div>span,
body[class*='blck-']:not(.onav) .bn>div>span {
  background-color: #061a2e;
}

body[class*='whte-']:not(.onav) .bn>div>span {
  background-color: #fff;
}

html.creaOpen .bn>div>span:nth-child(1) {
  top: 4px;
  transform: rotate(45deg);
}

html.creaOpen .bn>div>span:nth-child(2) {
  bottom: 4px;
  transform: rotate(-45deg);
}

#ld {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ld .bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#ld .logo {
  width: calc(180px + 100 * (100vw - 320px) / 1080);
  z-index: 5;
  overflow: hidden;
}

#ld .logo svg {
  fill: #fff;
}

#sv {
  min-height: 100vh;
}

#sv .rg {
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  padding-left: 65%;
}

#sv .cir {
  width: 76vh;
  height: 76vh;
  border-radius: 50%;
  top: 12vh;
}

#sv .circ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #cdcccf;
}

#sv .circm {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
}

#sv .rnd {
  width: 1px;
  height: 1px;
  position: absolute;
}

#sv .rnd>div {
  width: calc(120px + 50 * (100vw - 320px) / 1080);
  height: calc(120px + 50 * (100vw - 320px) / 1080);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sv .rnd>div>div {
  transform-origin: center center;
  box-shadow: 0 1px 3px 1px rgba(99, 0, 243, 0.2);
  width: 5%;
  height: 5%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#sv .rnd i {
  font-size: calc(50px + 30 * (100vw - 320px) / 1080);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sv .crc {
  position: absolute;
}

#sv .crc>div {
  position: absolute;
  width: 100%;
  height: 100%;
}

#sv .crc:nth-child(1) {
  height: 1px;
  width: 38vh;
  right: 0;
}

#sv .crc:nth-child(1)>div {
  right: 0;
}

#sv .crc:nth-child(1) .rnd {
  left: 0;
}

#sv .crc:nth-child(2) {
  height: 38vh;
  width: 1px;
  bottom: 0;
}

#sv .crc:nth-child(2)>div {
  bottom: 0;
}

#sv .crc:nth-child(2) .rnd {
  top: 0;
}

#sv .crc:nth-child(3) {
  height: 1px;
  width: 38vh;
  left: 0;
}

#sv .crc:nth-child(3)>div {
  left: 0;
}

#sv .crc:nth-child(3) .rnd {
  right: 0;
}

#sv .crc:nth-child(4) {
  height: 38vh;
  width: 1px;
  top: 0;
}

#sv .crc:nth-child(4)>div {
  top: 0;
}

#sv .crc:nth-child(4) .rnd {
  bottom: 0;
}

#sv .lgt {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 50%;
  padding-left: 15%;
}

#sv .lgt h3 {
  font-weight: 200;
  font-size: calc(18px + 10 * (100vw - 320px) / 1080);
  line-height: 1.3;
}

#sv .lgt h3 strong {
  display: block;
}

#sv .lgt .tx {
  width: 80%;
  line-height: 1.8;
  /* margin: calc(20px + 5%) 0; */
  color: #666;
  letter-spacing: 0.25px;
}

.sp-5 {
  position: absolute;
  top: 0;
}

.lgtxc {
  text-align: left;
  height: 80vh;
  min-height: 600px;
  max-height: 950px;
  display: flex;
  align-items: center;
}

.lgtxc .ct>div {
  width: calc(50% - 100px);
  left: calc(50% + 100px);
}

.lgtxc h3>* {
  display: block;
}

.lgtxc h3>strong {
  font-weight: 800;
}

.lgtxc .txt {
  width: 100%;

  line-height: 1.8;
  /* margin: calc(25px + 2%) auto 0; */
  color: #fff;
}

.lgtxc .txt strong {
  font-weight: 800;
}

@keyframes fireworkone {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.15;
  }

  50% {
    opacity: 0.3;
  }

  75% {
    opacity: 0.15;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fireworktwo {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.45;
  }

  50% {
    opacity: 0.8;
  }

  75% {
    opacity: 0.45;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fireworthree {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.55;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.55;
  }

  100% {
    opacity: 0;
  }
}

@keyframes elastic-pulse {
  0% {
    transform: scale(0.2);
  }

  100% {
    transform: scale(1);
  }
}

html:not(.mob) #cof .slc li>div:hover {
  background-color: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
}

.circle1 {
  border-radius: 50%;
  background-color: #1a73e8;
  width: 200px;
  height: 200px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

@keyframes scaleIn {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.5;
  }

  to {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

.circle1 {
  border-radius: 50%;
  background-color: #1a73e8;
  width: 200px;
  height: 200px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}

@media screen and (max-width: 1250px) {
  #sv .rg {
    padding-left: 75%;
  }

  #sv .lgt {
    padding-right: 35%;
  }
}

@media screen and (min-width: 901px) {
  #sv .rg>div {
    transform: none !important;
  }
}

@media screen and (max-width: 950px) {
  .ec {
    display: block;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .ec> :not(.cov) {
    height: auto;
  }

  .ec section:not(.cov) {
    display: block;
  }

  .ec .part-2 .ct,
  .ec .pres .ct,
  .ec section>.ct {
    padding: calc(45px + 50 * (100vw - 320px) / 1080) 0;
    width: 85vw;
    /* margin: auto; */
  }

  .ec .cov .tc {
    left: 50%;
    transform: translateX(-50%);
  }

  .ec .tc i:before {
    display: block;
    transform: rotate(90deg);
  }

  .ec .mck {
    /* margin: 5vh auto; */
  }

  .ec .mck-5 {
    margin-bottom: -3vh;
  }

  .ec .mck img {
    max-height: auto;
    max-width: 100%;
  }

  .ec .mck.mck-1 {
    left: -10vw;
  }

  .ec .mck.mck-3 {
    margin-top: -15vw;
  }

  .ec .bga {
    width: 100%;
    height: calc(350px + 250 * (100vw - 320px) / 1080);
  }

  .ec .bga>div {
    width: 100%;
    height: 150%;
  }

  .line-ec {
    height: 2px;
  }

  .ec .part-lg .ct {
    display: block;
  }

  .ec .part-lg .lgs {
    flex-direction: column;
    /* margin: calc(30px + 3%) auto calc(50px + 200 * (100vw - 320px) / 1080); */
    margin-left: 0;
  }

  .ec .part-lg .lgs img {
    width: calc(350px + 200 * (100vw - 320px) / 1080);
    max-width: 85%;
    /* margin-left: auto; */
    /* margin-right: auto; */
  }

  .ec .part-lg .lgs img+img {
    margin-top: calc(40px + 3%);
  }

  .ec section.nxt {
    width: 100%;
    height: 100vh;
    display: flex;
  }

  #sv .rg {
    padding-left: 0 !important;
    left: 0;
  }

  #sv .rg>div {
    transform: translateY(-70%) rotate(-90deg);
    transform-origin: center center;
  }

  #sv .rg .cir {
    top: 0;
    width: 100vw;
    height: 100vw;
  }

  #sv .rg .rnd {
    transform: rotate(90deg);
  }

  #sv .lgt {
    padding: 40vh 0 0 !important;
    text-align: center;
    padding-right: 16px !important;
  }

  #sv .lgt .tx {
    width: 100%;
    padding: 0 calc(20px + 5%);
    margin: calc(25px + 15 * (100vw - 320px) / 1080) 0;
  }

  #sv .crc:nth-child(1),
  #sv .crc:nth-child(3) {
    width: 50vw;
  }

  #sv .crc:nth-child(2),
  #sv .crc:nth-child(4) {
    height: 50vw;
  }

  #sv .lgt h3 strong {
    font-weight: 900;
    display: block;
  }

  .eavl {
    text-align: center;
    margin-top: 0 !important;
    position: relative;
    align-items: flex-end;
  }

  .eavl h2 {
    display: none;
    transform: none !important;
    opacity: 1 !important;
    width: 100%;
    top: 100vh;
    left: 0;
  }

  .eavl .ct {
    width: 100%;
    text-align: center;
  }

  .lgtxc .ct>div {
    width: 100%;
    left: 0;
    text-align: left;
  }

  .cirbc .ico,
  .cirbc svg {
    top: 30%;
  }
}

@media screen and (max-width: 780px) {
  #et .tc {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {
  .circle1 {
    border-radius: 50%;
    background-color: #1a73e8;
    width: 110px;
    height: 110px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  }

  .addExtraSpace1 {
    margin-left: 10px;
  }

  .header-group h3 {
    line-height: 30px;
    margin: 40px 0 40px -44px;
  }

  .addbackground {
    background: #f7f7f7;
    z-index: 999;
  }

  .lgt.fx .header-group {
    padding-left: 16px;
  }

  #sv .lgt .tx {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .ct .header-group {
    padding-left: 16px;
  }

  .ct .header-group h3 {
    color: #000;
  }

  .lgtxc h3 {
    left: 5%;
  }

  .cirbc .ico {
    top: 22% !important;
  }

  .circle_img {
    top: 0%;
  }

  .circle_img img {
    margin-top: 104px;
  }

  .circle1 {
    border-radius: 50%;
    background-color: #1a73e8;
    width: 110px;
    height: 110px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(0%);
  }
}

.animation-cartoon-img {
  animation: floating 2s ease-in-out infinite alternate;
}

.app-c #sv .lgt {
  background: url(../../../../../public/assets/informative/circle-1.png) top left no-repeat, url(../../../../../public/assets/informative/circle-1.png) bottom center no-repeat;
  background-size: 20%, 25%;
}

.bgz {
  background: url(../../../../../public/assets/informative/circle-1.png) top left no-repeat, url(../../../../../public/assets/informative/circle-1.png) bottom right no-repeat;
  background-size: 40%, 40%;
}

.ani {
  height: 90vh;
  width: 50%;
  display: flex;
  align-items: center;
}