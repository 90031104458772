.partners-container {
    margin: 2rem 0;
}

.partners-container .partners-sections_headings {
    color: var(--cold-black-color);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 10px;
}

.partners-container .partners-img {
    width: 100%;
    height: 100%;
}

.partners-container_box-3 {
    border-radius: .85rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, .45) 20%, rgba(0, 0, 0, 0.001) 50%),
        url(../../../../public/assets/property-screen-img1.png);
    height: 270px;
    width: 100%;
    background-size: cover;
    background-repeat: round;
    display: flex;
    justify-content: space-between;
    padding: 0 20px 10px 20px;
    /* align-items: end; */
    cursor: pointer;
    transition: all .3s ease-in;
}
.partners-container_box-3:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 15px 2px rgba(191, 191, 191, .45);
}

.partners-container_box-1 {
    border-radius: .75rem;
    width: 100%;
    height: 48%;
    margin-bottom: 10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, .75) 20%, rgba(0, 0, 0, .45) 20%, rgba(0, 0, 0, 0.001) 80%),
        url(../../../../public/assets/property-screen-img2.png);
    background-repeat: round;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    /* align-items: end; */
    padding: 0 20px 10px 20px;
    cursor: pointer;
    transition: all .3s ease-in;
}
.partners-container_box-1:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 15px 2px rgba(191, 191, 191, .45);
}

.partners-container_box-2 {
    border-radius: .75rem;
    width: 100%;
    height: 48%;
    background: linear-gradient(0deg, rgba(0, 0, 0, .75) 20%, rgba(0, 0, 0, .45) 20%, rgba(0, 0, 0, 0.001) 100%),
        url(../../../../public/assets/property-screen-img3.png);
    background-repeat: round;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    /* align-items: end !important; */
    padding: 0 20px 10px 20px;
    cursor: pointer;
    transition: all .3s ease-in;
}
.partners-container_box-2:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 15px 2px rgba(191, 191, 191, .45);
}

.partners-container_span-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: var(--color-white);
    transition: all .3s ease-in;
}

.partners-container .partners-container_box {
    padding: 15px 0 36px 0;
    border: 1.5px solid var(--wild-sand);
}

.partners-container .partners-container_span-5 {
    display: block;
    padding-left: 30px;
    font-weight: 600;
    font-size: 24px;
}

.partners-container .partners-container_span-6 {
    display: block;
    padding: 10px 30px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: var(--silver-surfer);
}

.listing-container {
    background: var(--lite-skyblue);
    height: 240px;
    padding-right: 20px;
}

.listing-container_col-1 {
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.listing-container_span {
    font-weight: 700;
    font-size: 32px;
    display: block;
}

.listing-container_link {
    margin-top: 10px;
    background: var(--primary-color);
    width: fit-content;
    color: var(--color-white);
    text-decoration: none;
    font-size: 14px;
    padding: 8px 30px;
}

.listing-container_link:hover {
    color: var(--color-white) !important;
}

.listing-container_col-2 {
    display: flex;
    /* align-items: end; */
    /* justify-content: end; */
}

.listing-container_img {
    width: 100%;
}

.partners-container-hr {
    margin: 0.5rem 0;
}    

/* mediaQueri */
@media(max-width: 550px) {
    .partners-container_span-1 {
        font-weight: 400;
        font-size: 12px;
    }
}
@media(max-width: 768px) and (min-width: 550px) {
    .partners-container_span-1 {
        font-weight: 400;
        font-size: 14px;
    }
}