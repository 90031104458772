.partners-fourth__card {
    margin-top: 30px;
    padding: 30px 20px 0 20px;
    background: #FFECB4;
}
.partners-fourth__card .fourth-card__img {
    width: 100%;
    height: 100%;
}
.partners-fourth__card .fourth-card__span-2 {
    display: block;
    font-size: 24px;
    width: 90%;
    padding-bottom: 10px;
    font-weight: 600;
}
.partners-fourth__card .fourth-card__span-3 {
    padding-bottom: 25px;
    display: block;
    /* font-size: 24px; */
    width: 90%;
    font-weight: 400;
}
.fourth-card__link {
    margin-bottom: 20px;
    /* height: 100px; */
    color: var(--purple-color);
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
}
.fourth-card__link:hover {
    color: var(--purple-color);
}