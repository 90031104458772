.stats-row {
    background: url(../../../../public/assets/informative/circle-2.png) top left no-repeat, url(../../../../public/assets/informative/circle-2.png) bottom center no-repeat, url(../../../../public/assets/informative/circle-2.png) top right no-repeat, var(--lite-skyblue);
    background-size: 15%, 10%, 15%;
    padding: 40px 80px 20px 80px;
    width: 100%;
    margin: auto;
    margin-top: 6rem;
}

.stats-row-col {
    text-align: center;
    margin-bottom: 20px;
}

.stats-row-img {
    width: 16%;
}

.stats-row-count {
    font-weight: 700;
    font-size: 30px;
    padding: 10px 0 0 0;
}

.stats-row-p {
    font-weight: 400;
    font-size: 18px;
    color: var(--silver-surfer);
}

@media (max-width: 992px) {
    .stats-row-img {
        width: 33%;
    }
}

@media (max-width: 492px) {
    .stats-row-img {
        width: 35%;
    }
    .stats-row{
        padding: 40px 20px 20px 20px;
    }
    .stats-row-count{
        font-size: 26px;
    }
    .stats-row-p{
        font-size: 15px;
    }
}