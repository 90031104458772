.broker-banner {
    background: url(../../../../public/assets/informative/circle-2.png) top left no-repeat, url(../../../../public/assets/informative/circle-2.png) bottom right no-repeat, var(--lite-skyblue);
    position: relative;
    background-size: 15%, 30%;
}

.broker-banner-col-1 {
    padding-left: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
}

.broker-banner-h4 {
    font-weight: 700;
    font-size: 33px;
    line-height: 50px;
}

.broker-banner-p {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: var(--silver-surfer);
    margin-bottom: 24px;
}

/* =============== col-2
===================== */
.broker-banner-col-2 {
    display: flex;
    /* align-items: end; */
    justify-content: flex-start;
}

.broker-banner-cartoon {
    width: 100%;
}

/* ================= col-3 
========================*/





/* mediaQueri */
@media(max-width: 992px) {
    .broker-banner-col-1 {
        height: auto;
        padding: 40px 20px 20px 6%;
    }

    .broker-banner-col-2 {
        display: none;
    }
}