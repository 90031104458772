.youtube-preview {
    position: relative;
    width: 640px;
    height: 390px;
    cursor: pointer;
  }
  
  .thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: black;
  }
  
  .thumbnail-image {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  }
  