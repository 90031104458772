.profile__sidebar__wrapper {
  border-radius: 25px;
  background: none !important;
  box-shadow: none !important;
}

.profile__sidebar__wrapper__icons {
  width: 33.95px;
  height: 33.95px;
  background: #fdfdfd;

  box-shadow: 0px 4px 14px rgba(69, 69, 69, 0.15) !important;
  border-radius: 8;
}

.profile__sidebar__wrapper .nav {
  background: #ffffff;
  border-radius: 10px;
}

.profile__sidebar__wrapper .nav-link {
  color: #000000 !important;
  padding: 10px 20px !important;
}
.profile__sidebar__wrapper .nav-link {
  border-bottom: 0.481264px solid white !important;
}
.profile__sidebar__wrapper .nav-link.active,
.profile__sidebar__wrapper .nav-item:last-child .nav-link {
  border: none !important;
}

.profile__sidebar__wrapper .nav-link.active,
.profile__sidebar__wrapper .nav-item.show .nav-link {
  border-radius: 10px;
  border: none !important;
  letter-spacing: 0.01em !important;
  color: #ffffff !important;
}

.profile__sidebar__wrapper .nav-link {
  display: flex;
  color: #000000 !important;
  font-weight: 400;
  font-size: 13.5783px;
  letter-spacing: 0.02em;
}

.profile__image {
  width: 120px;
  height: 120px;
}

.profile__sidebar__wrapper h4 {
  font-weight: 400;
  font-size: 18.7982px;
  color: #282431;
}

.profile__sidebar__wrapper p {
  font-weight: 400;
  font-size: 13.023px;
  color: #282431 !important;
  opacity: 0.5;
}

.profile__image img {
  width: 90%;
  height: 90%;
  object-fit: cover;
}

.profile__image__overlay {
  position: absolute;
  top: 0px;
  right: 10px;
  padding: 0.3rem;
  transform: translate(50%, 50%);

  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.profile__image__overlay:hover {
  transform: translate(50%, 50%) scale(1.2);
}

/* profile form */
.profile__form .form-label {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.profile__form input,
.profile__form select {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  padding: 10px;
  background: #f8f8f8;
  border: 1px solid #e9e9e9;
}
.profile__main.card h3 {
  font-weight: bold;
  color: #000000;
  margin-bottom: 0;
}
