.dashboard-chat {
  background-color: var(--primary-color);
  width: 73px;
  height: 73px;
  z-index: 100000;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  box-shadow: 0px 0px 17.59px rgba(0, 0, 0, 0.47) !important;
}
