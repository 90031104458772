input.invalid {
  color: var(--carnation-color) !important;
  font-weight: 400;
  font-size: 0.9rem;
}

.user-img {
  width: 30px;
  height: 30px;
}

.forget-password {
  font-size: 0.875rem;
  color: unset;
}

.login__user h1 {
  font-weight: 700;
  font-size: 28px;
}

.login__user figcaption {
  font-weight: 400;
  font-size: 16px;
  opacity: 0.5;
  color: #000;
}

/* login form */
.login__form form label {
  font-weight: 400;
  font-size: 0.9rem;
  color: var(--cold-steel-color);
}

.login__form p {
  font-size: 14px;
  color: var(--cold-steel-color);
}
.login-shadow{
  box-shadow: 0px 6.24258px 35.2798px rgb(0 0 0 / 7%);
  padding: 0;
  border-radius: 10px;
}

.login__form .invalid-feedback,
.login__form__forgetpassword,
.login__form .form-check-inline .form-label {
  font-weight: 400;
  font-size: 14px;
  color: var(--carnation-color);
}

.login__form__forgetpassword,
.login__form .form-check-inline .form-label {
  color: var(--cold-steel-color);
}

.login__form__forgetpassword {
  margin-top: 0.25rem;
}

.nes__dsa__login__img {
  border-radius: 10px 0px 0px 10px;
}

.nes__dsa__login__formwrapper {
  /* box-shadow: 0px 6.24258px 35.2798px rgba(0, 0, 0, 0.07); */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 575px) {
  .nes__google__facebook img {
    width: 80px;
  }
}
