.login-modal {
    padding: 30px 0;
    text-align: center;
}

.login-modal-heading {
    display: block;
    font-weight: 700;
    font-size: 1.45rem;
    margin: 0;
}

.login-modal-sub-heading {
    display: block;
    color: var(--silver-surfer);
    font-size: 0.9rem;
}

.login-modal-broker-link {
    cursor: pointer;
    background: var(--primary-color);
    width: 100%;
    margin: auto;
    margin-top: 30px;
    box-shadow: 0px 4px 20px 0px rgba(39, 143, 217, .35);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 6px 0;
}

.login-modal-borker-text {
    color: var(--color-white);
    font-weight: 400;
    font-size: 0.9rem;
    margin-left: 10px;
}
.login-modal-img {
    width: 80%;
}