.main-testimonial-body {
    position: relative;
    padding-bottom: 48px;
    background: linear-gradient(to bottom,
            #F4FAFF 0%,
            #F4FAFF 65%,
            white 60%,
            white 100%);
    z-index: -1;
}

.quote-image {
    width: 100%;
    height: 250px;
    position: absolute;
    z-index: -10;
    right: 1.45%;
    top: 30px;
    padding: 0px;
}

.heading {
    margin-bottom: 1%;
}

.text-orange {
    font-style: normal;
    font-weight: 600;
    font-size: 26.1051px;
    line-height: 56px;
    text-transform: uppercase;
    color: #FC5C67;
}

.person-image {
    height: 75px;
    width: 75px;
}

.test-card {
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 0px 15px 2px rgba(191, 191, 191, .2);
    border: 1px solid transparent;
    padding: 50px 30px 30px 30px;
    transform: scale(1);
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.test-card:hover {
    transform: translateX(1rem) !important;
    transition-duration: 300ms !important;
    border: 1px solid #1d1c1c;
}

.testimonial-desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: var(--gray-color);
    padding-right: 10px;
    text-align: justify;
}

.test-name {
    font-weight: 600;
    font-size: 18px;
    /* line-height: 28px; */
    display: flex;
    align-items: center;
    text-align: right;
    color: #282431;
}

.test-company {
    font-weight: 400;
    font-size: 14px;
    /* line-height: 28px; */
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #282431;
    opacity: 0.5;
    text-align: right;
}

.card-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}