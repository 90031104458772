.residency {
    background: url(../../../../public/assets/informative/blocks.png);    
    padding: 10% 0 6% 0;
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
}

.residency-row {
    display: flex;
}

.col-1-box {
    margin-top: 30px;
}

.col-1-img {
    width: 100%;
}

.col-1-box-span_1 {
    font-weight: 700;
    font-size: 40px;
    display: block;
    color: var(--cold-black-color);
}

.col-1-box-span_2 {
    font-weight: 400;
    font-size: 15px;
    display: block;
    line-height: 25px;
    padding: 1% 0;
    color: var(--silver-surfer);
}


/* //////////////////// */
.residency-col-2 {
    /* position: relative; */
    position: absolute;
    top: 10%;
    right: -18%;
}

.animation-div {
    width: 80%;
}

.animation-svg {}

.animation-div-3 {
    position: absolute;
    top: 15%;
    right: 38%;
}

.animation-png {
    animation: smooth-jumping 3s infinite;
}

@keyframes smooth-jumping {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(40px);
    }
}