/* Current Projects ================
================================= */
.past-project_slider {
    padding: 0 3%;
}

.past-project_row {
    padding: 0 2px;
}

.past-project_slider .slick-next:before,
.slick-prev:before {
    font-size: 30px;
    color: var(--cold-black-color);
}

.past-project_slider .past-project-slider_col-1 {
    width: 50%;
    margin-right: auto;
    padding: 0;
    border-radius: 2rem;
    position: relative;
}

.past-project_slider .past-project-slider_tag {
    position: absolute;
    top: 0;
    left: 0;
    background: #39ADAF;
    color: var(--color-white);
    padding: 2px 6px;
    border-radius: 0 5px 5px 0;
    text-transform: uppercase;
    margin: 10px 0;
    font-size: .8rem;
}

.past-project_slider .past-project-slider_icon1 {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--cold-black-color);
    background: var(--color-white);
    padding: 2px 6px;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    font-size: 18px;
    transition: all .2s;
}

.past-project_slider .past-project-slider_icon1:hover {
    transform: scale(1.04);
}

.past-project_slider .past-project-slider_icon2 {
    position: absolute;
    bottom: 0;
    right: 0;
    color: red;
    padding: 2px 6px;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
    font-size: 18px;
}

.past-project-slider_col-1 .past-project-slider_img {
    height: 100%;
    width: 100%;
}

.past-project_slider .past-project-slider_col-2 {
    width: 50%;
    padding: 20px 0 20px 14px;
}

.past-project_slider .past-project_span-1 {
    font-weight: 700;
    color: var(--cold-black-color);
    font-size: 16px;
    display: block;
    transition: all .2s ease-in;
}
.past-project_slider .past-project_span-1:hover {
    color: var(--primary-color) !important;
}

.past-project_slider .past-project_span-2 {
    font-weight: 400;
    font-size: 14px;
    color: var(--silver-surfer);
    display: block;
    margin-top: 8px;
}

.past-project_slider .past-project_span-3 {
    font-weight: 700;
    padding-right: .5rem;
    font-size: 14px;
    color: var(--primary-color);
    margin-top: 8px;
    display: block;
}

.past-project_slider .past-project-slider_star {
    color: #ffb630;
    font-size: 16px;
    margin-right: 1.5px;
}

.past-project-slider-card {
    border: 2px solid var(--wild-sand);
}