.login__header__img {
    width: 170px;
  }
  
  .login__header .nav-link {
    font-weight: 400;
    font-size: 15px;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
  }
  
  .login__header .nav-link:hover {
    background-color: var(--primary-color);
    color: #ECF7FF;
  }

  @media only screen and (max-width: 600px) {
   .icon-bar{
display: flex;
justify-content: flex-start;
  }
}