.informative-footer {
    background: var(--primary-color);
    padding: 40px 0 30px 0;
}

.informative-footer-div {
    width: 90%;
    margin: auto;
}
.informative-footer_logo{
    width: 200px;
}
.footer-col-1-span {
    display: block;
    margin-top: 18px;   
    color: var(--color-white);
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.6;
    /* width: 80%; */
}

.informative-footer-icons-div {
    margin-top: 18px;
}

.footer-col-1-icons:not(:first-child) {
    margin-left: 20px;
}
.footer-col-1-icons{
    width: 36px; 
    transition: transform 400ms;
    margin-right: 15px;
}
.footer-col-1-icons:hover{
    transform: scale(1.1);
}

.informative-footer_logo{
    width: 200px;
}


/* Second Col Css =======
=======================*/
.footer-col-2 {
    padding-left: 40px;
}

.footer-col-2-span {
    display: block;
    margin-top: 16px;
    color: var(--color-white);
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 20px;
}

.informative-footer-link {
    color: var(--color-white) !important;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}
/* 
.footer-col-3 {
    margin-top: 62px;
} */

/* Third Col */
.footer-mail-div {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.footer-mail-span {
    display: block;
    color: var(--color-white);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}
/* Footer Rights */
.informative-footer_rights {
    /* background: var(--primary-color-darker); */
    background: #1278C0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px 0;
}
.informative-footer_rights_p {
    color: var(--color-white);
    font-weight: 400;
    font-size: 16px;
    padding-top: 14px;
}
/* media queri */
@media(max-width: 992px) {
    .informative-footer-div {
        width: 100%;
        margin: auto;
    }

    .footer-col-1-span {
        width: 70%;
    }

    .footer-col-2 {
        margin-top: 30px;
        padding-left: 10px;
    }

    .footer-col-3 {
        margin-top: 0px;
        padding-left: 10px;
    }

    .footer-col-4 {
        margin-top: 30px;
        padding-left: 10px;
    }
    .informative-footer_rights {
        height: 100px;
    }
    .informative-footer_rights_p {
        /* font-size: 14px; */
    }
}