.admin-dashboard {
    margin-top: 10px;
}

.admin-dashboard-title {
    font-size: 28px;
    font-weight: 600;
}
.admin-dashboard-row {
}
.admin-dashboard_top-card {
    margin-top: 14px;
    padding: 0;
    padding-right: 20px;
}

.admin-dashboard_top-card-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    padding: 24px 20px 19px 20px;
    box-shadow: 0px 0px 15px 0px rgba(237, 237, 237, 1);
}

.admin-dashboard_top-card_text-div {}

.admin-dashboard_top-card-title {
    font-weight: 400;
    font-size: 14px;
    color: var(--silver-surfer);
    display: block;
}

.admin-dashboard_top-card-number {
    display: block;
    font-weight: 700;
    margin-top: 5px;
    font-size: 28px;
}

.admin-dashboard_top-card-circle-div {
    height: 100%;
}

.admin-dashboard_top-card-circle {
    width: 60px !important;
}