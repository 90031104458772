.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #278fd9 !important;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  border-radius: 100px;
  border: none !important;
  padding: 10px 30px !important;
}

.tabwidth{
  width: 100% !important;
}

.nav-tabs .nav-link {
  color: #000000;
}

#dashboard-tabs .nav-tabs .nav-link {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #000000 !important;
}

.nav-tabs {
  background: #ffffff !important;
  border: 1.19633px solid #ebecf0 !important;
  border-radius: 100px !important;
  padding: 20px !important;
  display: flex !important;
  /* justify-content: space-between !important; */
  align-items: center !important;
  /* gap: 1rem; */
}

.nav-tabs > * {
  flex: 1;
}
.nav-tabs > * > button {
  width: 100%;
  font-size: 16px !important;
}
#dashboard-tabs .nav-tabs {
  border: 0 !important;
}
.dashboard-tabs-container {
  overflow: auto;
  border: none !important;
  min-width: 100%; /* Adjust as needed */
}
@media screen and (max-width: 768px) {
  .nav-tabs > * > button {
    width: 100%;
    font-size: 1rem !important;
  }
}

.active-tab {
  background-color: #007bff;
  color: #fff;
}
