.login__page__modal .modal-title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #000000;
  margin-left: auto;
}

.login__page__modal p {
  font-weight: 400;
  font-size: 13px;
  line-height: 37px;
  color: #282431;
}

.login__page__modal .form-label {
  font-weight: 400;
  font-size: 15px;
  color: #282431;
}

.login__page__modal input {
  border-radius: 5px;
}

.custom__modal__close {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b5b5b5 !important;
}

.nes__dashboard__modal .nav-tabs {
  padding: 0.2rem 0.5rem !important;
  width: min(100%, 358px);
  margin-inline: auto;
  background-color: #f8f7f7 !important;
}

.nes__dashboard__modal__icon {
  width: 145px;
  height: 145px;
  background-color: hsl(135deg 59% 49%);
}

.nes__dashboard__modal__success span {
  font-weight: 700;
  font-size: 25px;
  letter-spacing: -0.01em;
  color: #000000;
}

.nes__dashboard__modal__success p {
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  color: #838ba1;
}
