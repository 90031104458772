.property-referrals {
    margin-top: 25px;
}

.property-referrals-div {
    box-shadow: 0px 0px 15px 0px rgba(237, 237, 237, 1);
    border-radius: 12px;
    padding: 16px;
}

.property-referrals-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.property-referrals-heading-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.property-referrals-heading {
    font-size: 18px;
    font-weight: 400;
}

.property-referrals-percentage {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.property-referrals-percentage-div {
    display: flex;
    align-items: center;
}

.property-referrals-color-block {
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.property-referrals-percentage-title {
    font-size: 16px;
    color: var(--cold-black-color);
}

.property-referrals-percentage-percent {
    color: var(--silver-surfer);
    font-size: 16px;
}