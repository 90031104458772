.inputCustom {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  height: 3rem;
}
.inputFile {
  width: 100%;
  height: 17rem;
  margin-bottom: 4rem;
  background-color: #f8f8f8;
  border-radius: 15.31px;
  border: 1px dashed gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  flex-direction: column;
}
