.HowWorkSelectBar-col {
    padding: 0 6%;
    margin: 5% 0 2% 0;
}

.HowWorkSelectBar-div {
    background: var(--color-white);
    border: 1px solid var(--divider);
    display: flex;
    border-radius: 10rem;
    padding: 6px;
}

.HowWorkSelectBar-box {
    font-size: 0.9rem;
    font-weight: 400;
    padding: 10px;
    cursor: pointer;
    border-radius: 10rem;
    width: 50%;
    text-align: center;
    transition: all .4s linear;
}

.HowWorkSelectBar-box-active {
    background: var(--primary-color);
    color: var(--color-white);
}


@media(max-width: 523px) {
    .HowWorkSelectBar-box {
        font-size: 16px;
        padding: 10px;
    }

    .HowWorkSelectBar-div {
        padding: 5px 6px;
    }
}

@media(max-width: 437px) {
    .HowWorkSelectBar-box {
        font-size: 13px;
        padding: 8px;
    }
}