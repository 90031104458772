.about-us-banner {
    background: linear-gradient(rgba(0, 0, 0, .55), rgba(0, 0, 0, .45)),
        url(../../../public/assets/informative/aboutUs.png);
    width: 100%;
    height: 310px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us-banner-heading {
    font-weight: 700;
    font-size: 59px;
    color: var(--color-white);
}

.about-us_box1 {
    background: url(../../../public/assets/informative/aboutUsImg2.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8% 0 2% 0;
}

.about-us_box1-img {
    width: 100%;
}

.about-us_box1_btn-div {
    margin-top: 3%;
    display: flex;
    /* align-items: start; */
    /* justify-content: end; */
    padding-right: 5%;
}

/* .about-us_box1_btn {
    background: #000;
    padding: 4px 30px 2px 10px;
    text-decoration: none;
    border-radius: 6px;
} */

.about-us_box1_icon-span-1 {
    font-size: 10px;
    font-weight: 200;
    color: var(--color-white);
}

.about-us_box1_icon-span-2 {
    color: var(--color-white);
    font-size: 18px;
}

.about-us_box1_icon-span-3 {
    display: block;
    font-weight: 700;
    font-size: 16px;
    padding-top: 2px;
}


.about-us_box2 {
    padding-top: 3%;
    padding-left: 3%;
}

.about-us_box-span3 {
    font-weight: 400;
    font-size: 15px;
    display: block;
    line-height: 25px;
    padding: 1% 0;
    color: var(--silver-surfer);
}

.about-us_box-span4 {
    font-weight: 400;
    font-size: 15px;
    display: block;
    line-height: 25px;
    padding-top: 1%;
    color: var(--silver-surfer);
}

.about-second-card__icon {
    width: 150px;
}

/* media queri */
@media (max-width: 490px) {
    .about-us_box1 {
        padding-bottom: 10%;
        background: #000 !important;
    }

    .about-second-card__icon {
        width: 130px;
    }

    /* .about-us_box1_btn {
        padding: 6px 0 4px 6px;
        width: 120px;
        margin: auto;
    } */

    .about-us_box-span2 {
        width: 100%;
    }

    .about-us_box1_icon-span-2 {
        font-size: 14px;
    }

    .about-us_box-span3,
    .about-us_box-span4 {
        text-align: justify;
    }
}

@media (max-width: 489px) {
    .about-us_box2 {
        padding-left: 0%;
    }
}

@media (max-width: 1200px) and (min-width: 490px) {
    .about-us_box1 {
        padding-bottom: 6%;
    }

    .about-us_box2 {
        padding-left: 0%;
    }

    .about-second-card__icon {
        width: 130px;
    }

    /* .about-us_box1_btn {
        padding: 6px 0 4px 6px;
        width: 140px;
        margin: auto;
    } */

    .about-us_box1_icon-span-2 {
        font-size: 14px;
    }

    .about-us_box-span3 {
        width: 95%;
    }
}