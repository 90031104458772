/* Ribbon */
.ribbonStyle {
  z-index: 9999;
  position: absolute;
  top: 7px;
  z-index: 10;
  padding: 0px 7px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  font-weight: bold;
  text-align: center;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.05em;
  color: #282431;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  zoom: 1;
  right: -2%;
  /* right: -3%; */
  min-width: 35%;
  max-width: 35%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.orange {
  background: #ff9600;
}

.ribbonStyle:after {
  content: '';
  position: absolute;
  z-index: -20;
  top: 100%;
  left: auto;
  border-style: solid;
  border-width: 0 0 10px 10px;
  right: 0;
}
.orange:after {
  border-color: transparent #ffb630;
}

/*END OF RIBBONSTYLE */

/* ribbon style 2 */

.ribbonStyle2 {
  position: absolute;
  padding: 0 1rem;
  font-size: 2em;
  line-height: 1.875em;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  border-radius: 0px 2.54px 2.54px 0px;
  background: #278fd9;
  top: 7px;
  left: 0;
  z-index: 9999;
}

/* ribbon style 2 code end here */

/* ribbon style 3 code start here */
.ribbonStyle3 {
  padding: 0.3rem 1rem;
  border-radius: 0px 0px 2.54px 2.54px;
  background-color: #fc5c67;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  color: #ffffff;
}
/* ribbon style 3 code end here */
