.contact-us {
    background: url(../../../../public/assets/informative/circle-2.png) top left no-repeat, url(../../../../public/assets/informative/circle-2.png) bottom center no-repeat, var(--lite-skyblue);
    background-size: 15%, 35%;
    height: 100%;
    box-shadow: 0px 0px 15px 2px rgba(232, 232, 232, .75);
}

.contact-us_col {
    padding: 90px;
}

.contact-us_span1 {
    font-weight: 700;
    font-size: 34px;
    display: block;
    color: var(--primary-color);
    width: 80%;
}

.contact-us_text-div {
    padding-top: 20px;
}

.contact-us_span2 {
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    color: var(--silver-surfer);
    display: block;
    margin-bottom: 40px;
    /* width: 80%; */
}

.contact-us_link {
    text-decoration: none;
}

.contact-us_span3 {
    font-weight: 400;
    display: block;
    line-height: 18px;
    color: var(--silver-surfer);
}

.contact-us_img {
    width: 100%;
}

@media(max-width: 492px) {
    .contact-us_col {
        padding: 40px 44px !important;
    }
}

@media(max-width: 998px) {
    .contact-us_col {
        padding: 40px 90px;
    }
}