.builder-banner {
    background: url(../../../../public/assets/informative/circle-2.png) top left no-repeat, url(../../../../public/assets/informative/circle-2.png) bottom right no-repeat, var(--lite-skyblue);
    position: relative;
    background-size: 15%, 30%;
    margin-bottom: 5rem;
    overflow-x: hidden;
}

.builder-banner-col-1 {
    padding-left: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
}

.builder-banner-h4 {
    font-weight: 700;
    font-size: 33px;
    line-height: 50px;
}

.builder-banner-p {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: var(--silver-surfer);
    margin-bottom: 24px;
}

/* =============== col-2
===================== */
.builder-banner-col-2 {
    display: flex;
    /* align-items: end; */
    justify-content: flex-start;
}

.builder-banner-cartoon {
    width: 100%;
}

.builder-banner-col-4 {
    display: none;
    padding: 30px 40px;
    margin-bottom: 2rem;
}

.builder-banner-col-4-div {
    background: var(--color-white);
    padding: 30px 20px;
    z-index: 10;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(214, 214, 214, 1);
}

/* ================= col-3 
========================*/

/* mediaQueri */
@media(max-width: 992px) {
    .builder-banner-col-1 {
        height: auto;
        padding: 40px 20px 20px 6%;
    }

    .builder-banner-col-2 {
        display: none;
    }

    .builder-banner-col-3 {
        display: none;
    }

    .builder-banner-col-4 {
        display: block;
    }
}