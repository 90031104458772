.css-1yhpg23-MuiTableCell-root {
  border-bottom: none !important;
}
.hello {
  border: none !important;
  outline: none !important;
}
.css-1ygcj2i-MuiTableCell-root {
  border-bottom: none !important;
}

.css-1ex1afd-MuiTableCell-root {
  border-bottom: none !important;
}
.css-1sz746r-MuiTableCell-root {
  border-bottom: none !important;
}
.css-xq3utx-MuiTableCell-root {
  border-bottom: none !important;
}
