.login__header__img {
  width: 170px;
}

.login__header .nav-link {
  font-weight: 400;
  font-size: 15px;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.login__header .nav-link:hover {
  background-color: var(--primary-color);
  color: #ffffff;
}

@media (max-width: 992px) {
  /* .nes__header .navbar-nav {
      display: flex;
      flex-direction: column;
    } */
}
