.partners-container .partners-third__card {
    padding: 30px 20px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), var(--lite-skyblue));
    margin-top: 30px;
}
.partners-container .third-card__img {
    width: 100%;
    height: 160px;
}
.third-card__p-1 {
    color: var(--cold-black-color);
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 15px;
    font-weight: 400;
}
.third-card__span-1 {
    color: var(--cold-black-color);
    font-weight: 600;
}
.third-card__link {
    color: var(--purple-color);
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
}
.third-card__link:hover {
    color: var(--purple-color);
}
.third-card__span-2 {
    display: block;
    color: var(--cold-black-color);
    padding-top: 8px;
    font-weight: 400;
}