.AgentPDPricingCard {
    margin-top: 15px;
    padding: 0;
}

.AgentPDPricingCard-col {
    padding: 5px 0;
    position: relative;
    width: 100%;
}

.AgentPDPricingCard-div_1 {
    padding: 14px 14px 21px 14px;
    border-radius: 20px;
    width: 100%;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.055);
}

.AgentPDPricingCard-div_2 {}

.AgentPDPricingCard-location_img {
    width: 100%;
    border-radius: 20px;
}

.AgentPDPricingCard-span_1 {
    color: var(--silver-surfer);
    font-size: 15px;
    display: block;
    font-weight: 300;
}

.AgentPDPricingCard-span_2 {
    font-size: 23px;
    font-weight: 900;
    line-height: 51px;
    /* display: block; */
}

.AgentPDPricingCard-span_3 {
    color: var(--silver-surfer);
    font-size: 15px;
    font-weight: 300;
}

.AgentPDPricingCard-span_4 {
    display: block;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    color: var(--primary-color);
}

.AgentPDPricingCard-div_3 {
    position: absolute;
    top: 8%;
    right: 0;
}

.AgentPDPricingCard-span_5 {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px 8px 20px;
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 10rem;
    background: #FFB630;
}

/* Media Queri */
@media(max-width: 605px) {
    .AgentPDPricingCard {
        padding: 0 8px;
    }
}