.broker__property__details__availability__left div,
.broker__property__details__availability__right {
  background-color: #efefef;
  border-radius: 6.72833px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13.4567px;
  text-align: center;
  color: #7d7f88;
}

.broker__property__details__card {
  background-color: #ffffff;
  box-shadow: 0px 7.86258px 44.4353px rgba(0, 0, 0, 0.07);
  border-radius: 18px;
}
