.feedback__card .card-title {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.feedback__card .card-footer button {
  background-color: #ffffff;
  box-shadow: 0px 0px 6.40217px rgba(0, 0, 0, 0.25);
  border-radius: 9.60326px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  outline: none;
  border: none;
}
