:root {
  font-family: 'Bahnschrift';
  /* colors */
  --primary-color: #278fd9;
  --purple-color: #bf0063;
  --primary-color-darker: #1980c9;
  --lite-skyblue: #eaf4fb;
  --cold-black-color: #282431;
  --color-white: #ffffff;
  --Pattens-Blue: #e0f2ff;
  --carnation-color: #fc5c67;
  --wild-watermelon-color: #fe5e6a;
  --wild-sand: #f6f6f6;
  --bg-red: #ff000e;
  --bg-red-darker: #b7010d;
  --gray-color: #b3b4b8;
  --silver-surfer: #7d7f88;
  /* divider */
  --divider: #d3d3d3;
  --transparent-danger: #ffe1e1;
  --transparent-succes: #eaf5e7;
}

/* Custom Scroll-bar */
html::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

html::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background: #278fd9;
  /* border-radius: 10px; */
}

body {
  overflow-x: hidden !important;
  font-size: 0.9em;
  line-height: 1.35;
  background: #ffffff !important;
}

@font-face {
  font-family: 'Bahnschrift';
  src: url(./fonts/Bahnschrift.ttf) format('truetype');
}

.form-control {
  font-size: 0.9rem;
}

::placeholder {
  color: #c6c6c6 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c6c6c6 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c6c6c6 !important;
}

/* userSelect */
::selection {
  background: var(--primary-color);
  color: var(--color-white);
}

.btn.btn-color-outline-primary {
  background: transparent;
  outline: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 400;
}

.btn.btn-color-outline-primary:hover {
  background-color: var(--primary-color);
  color: #ffffff;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #1c1c28;
  margin-top: 0.25rem !important;
  margin-left: 8px;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #278fd9;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #c7c9d9;
}

::placeholder {
  color: #8391a1;
}

.btn-shadow {
  box-shadow: 2px 10px 22px #278fd973;
}

.m-hide{
  display: block !important;
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .m-hide{
  display: none !important;
  }
  .col-sm-3 {
    width: 25%;
  }

  .col-sm-10 {
    width: 80%;
  }

  /* .col-sm-2 {
    width: 20%;
  } */

  .col-sm-6 {
    width: 50%;
  }
}

p {
  margin-bottom: 0.5rem;
}

/* Sections Headings */
.sections-heading {
  font-weight: 600;
  font-size: 17px !important;
  text-transform: uppercase;
  color: var(--carnation-color);
  display: block;
  margin-bottom: 5px;
}

.sections-sub-heading {
  text-transform: capitalize;
  font-weight: 900;
  color: var(--cold-black-color);
  display: block;
  font-size: 34px;
  line-height: 1.4;
  margin-bottom: 10px;
}

.cursor {
  cursor: pointer !important;
}

/* primary_button */
.primary_button {
  text-decoration: none !important;
  background: var(--primary-color) !important;
  color: var(--color-white) !important;
  padding: 10px 45px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
  border: 1px solid transparent !important;
  transition: all 0.4s ease-in !important;
}

.primary_button:hover {
  background: rgb(13 110 253 / 20%) !important;
  color: var(--primary-color) !important;
  border: 1px solid rgb(13 110 253 / 20%) !important;
}

.main-heading {
  font-family: 'Bahnschrift';
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0em;
}
.primary-heading {
  font-family: 'Bahnschrift';
  font-size: 29.07px;
  font-weight: 700;
  line-height: 36px;
}

.secondary-heading {
  font-family: 'Bahnschrift';
  font-size: 25.44px;
  font-weight: 700;
}
.secondary-heading2 {
  font-family: 'Bahnschrift', sans-serif;
  font-size: 30px;
  font-weight: 400;
}
.typoHeading {
  font-family: Bahnschrift;
  font-size: 28px;
  font-weight: 700;
}
.subText {
  font-family: Bahnschrift !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}
.subText1 {
  font-family: Bahnschrift !important;
  font-size: 17px !important;
  font-weight: 400 !important;
}
.subText2 {
  font-family: Bahnschrift !important;
  font-size: 15px !important;
  font-weight: 400 !important;

  color: #8b9199;
}
@media only screen and (max-width: 600px) {
  .typoHeading {
    font-size: 2px;
  }
  .subText {
    font-size: 18px !important;
  }
  .subText1 {
    font-size: 14px !important;
  }
  .subText2 {
    font-size: 10px !important;
  }
}
.trstyle {
  outline: none !important;
  border: 0 !important;
}
.visit-details-tab {
  width: 45% !important;
}
@media (max-width: 576px) {
  .visit-details-tab {
    width: 97% !important;
  }
}
@media (max-width: 900px) {
  .visit-details-tab {
    width: 97% !important;
  }
}

.customBorder{
width: fit-content;
height: 2.5rem;
overflow: hidden;
border: 1px solid #C7C9D9;
color: black;
display: flex;
justify-content: center;
align-items: center;
border-radius: 10px;
padding: 0px 10px;
}