.partners-container .partners-first__card {
    padding: 30px 0;
    padding-left: 25px;
    border: 2px var(--wild-sand) solid;
}
.partners-container .first-card__img {
    width: 100%;
    height: 160px;
}
.first-card__span-1 {
    display: block;
    color: var(--purple-color);
    padding-bottom: 6px;
    font-weight: 900;
}
.first-card__span-2 {
    display: block;
    color: var(--cold-black-color);
    padding-bottom: 6px;
    font-size: 21px;
    font-weight: 600;
}
.first-card__span-3 {
    display: block;
    padding-bottom: 12px;
    color: var(--cold-black-color);
    font-weight: 400;
}
.first-card__link {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 21px;
    text-decoration: none;
}