/* Listing */
.listing-container {
    background: var(--lite-skyblue);
    height: 240px;
    padding-right: 20px;
}

.listing-container_col-1 {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: capitalize;
}

.listing-container_span {
    font-weight: 700;
    font-size: 32px;
    display: block;
}

.listing-container_link {
    margin-top: 20px;
    background: var(--primary-color);
    width: fit-content;
    color: var(--color-white);
    text-decoration: none;
    font-size: 14px;
    padding: 8px 30px;
    border-radius: 8px;
}

.listing-container_link:hover {
    color: var(--color-white) !important;
}

.listing-container_col-2 {
    display: flex;
    /* align-items: end; */
    /* justify-content: end; */
}

.listing-container_img {
    height: 80%;
    width: 100%;
}

/* mediaQueri */
@media(max-width: 550px) {
    .listing-container {
        height: 180px;
    }

    .listing-container_col-1 {
        padding-left: 20px;
    }

    .listing-container_span {
        line-height: 30px;
        font-size: 18px;
        z-index: 1;
    }

    .listing-container_link {
        padding: 8px 20px;
        font-size: 12px;
    }

    .listing-container_img {
        width: 100%;
        height: 80%;
    }
}

@media(max-width: 768px) and (min-width: 550px) {
    .listing-container_col-1 {
        padding-left: 20px;
        width: 100%;
    }

    .listing-container_span {
        font-weight: 700;
        line-height: 40px;
        font-size: 21px;
        z-index: 1;
        display: block;
    }

    .listing-container_link {
        margin-top: 10px;
    }

    .listing-container_img {
        width: 110%;
        height: 90%;
    }
}