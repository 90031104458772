.tips-container {
    margin-top: -6rem;
    background: var(--color-white);
    height: 100%;
}

.tips-header {
    padding-bottom: 3rem;
    border-bottom: 1.4px solid var(--divider);
}

.tips-header_box {
    width: 42%;
    margin: auto;
    padding: 3% 0;
}

.tips-header_heading {
    font-weight: 700;
    font-size: 30px;
    text-transform: capitalize;
    color: var(--cold-black-color);
}

.tips_box-1 {
    margin: auto;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    width: 55%;
    justify-content: space-between;
}

.tips_user-name {
    font-weight: 400;
    font-size: 16px;
    color: var(--cold-black-color);
}

.tips_user-date {
    font-weight: 400;
    font-size: 16px;
    color: var(--cold-black-color);
}

.tips_box-2 {
    margin-top: 2rem;
}

.tips_box-3 {
    width: 90%;
    /* padding-right: 15%; */
    margin: auto;
}

.tips_box-2-span {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: var(--silver-surfer);
}

.tips_box-4 {
    margin: 3rem 0;
}

.tips_box-4-img {
    width: 100%;
    height: 100%;
}

.tips_box-5 {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
}

.tips_box-5-heading {
    font-weight: 600;
    font-size: 30px;
    color: var(--cold-black-color);
}

.tips_box-5-li {
    list-style: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: var(--silver-surfer);
}

.tips_box-6 {
    width: 90%;
    text-align: center;
    margin: auto;
    margin-top: 2rem;
}

.tips_box-6-span {
    font-weight: 600;
    font-size: 22px;
    color: var(--cold-black-color);
    line-height: 30px;
}

.tips_box-6-btn {
    padding: 10px 0;
    width: 20%;
    font-size: 0.9rem;
    border: none;
    border: 1px solid rgba(224, 224, 224);
    border-radius: 8px;
    background: var(--color-white);
    /* box-shadow: 0px 0px 8px rgba(224, 224, 224.25); */
    margin: 0 10px;
    transition: all .4s ease-in !important;
}

.tips_box-6-btn:hover{
    background: rgb(13 110 253 / 20%) !important;
    color: var(--primary-color) !important;
    border: 1px solid rgb(13 110 253 / 20%) !important;
}

/* Share */
.recent-post_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}
.recent-post_span3 {
    font-weight: 600;
    font-size: 28px;
    color: var(--cold-black-color);
    /* line-height: 38px; */
}
.recent-post_span4 {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    padding: 12px 30px 10px 30px;
    font-size: 16px;
    border-radius: 5px;
}
.share-box {
    margin-top: 20px;
}

.share-box_span1 {
    font-weight: 400;
    font-size: 18px;
    margin-right: 18px;
}

.share-box_span2 {
    font-size: 20px;
    color: var(--primary-color);
}
/* mediaQueri */
@media(max-width: 1200px) {
    .tips-header_box {
        width: 100%;
    }
}
@media(max-width: 998px) {
    .tips-header_heading {
        font-size: 34px;
    }
    .tips_box-6 {
        width: 100%;
    }
    .tips_box-6-btn {
        width: 35%;
        margin: 0 10px;
    }
}