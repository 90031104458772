.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #278fd9 !important;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  border-radius: 100px;
  border: none !important;
  padding: 10px 30px !important;
}

.nav-tabs .nav-link {
  color: #000000;
}

#dashboard-tabs .nav-tabs .nav-link {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #000000 !important;
}


#dashboard-tabs .nav-link {
  flex: 1 1 auto; /* Ensures tabs take equal space */
  text-align: center; /* Centers text within each tab */
}

#dashboard-tabs .nav-item {
  margin-bottom: 10px; /* Adds spacing between tabs on small screens */
}

@media (min-width: 576px) {
  #dashboard-tabs .nav-item {
    margin-bottom: 0; /* Removes bottom margin for larger screens */
  }
}

.nav-tabs {
  background: #ffffff !important;
  border: 1.19633px solid #ebecf0 !important;
  border-radius: 100px !important;
  padding: 5px !important;
  display: flex !important;
  align-items: center !important;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
}

.nav-tabs > * {
  flex: 1;
}
.nav-tabs > * > button {
  width: 100%;
}
