.client__card {
  border-radius: 15.9418px;
  background-color: #fff;
  box-shadow: 0px 4.84631px 21.2026px rgba(0, 0, 0, 0.07);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.client__card:hover {
  box-shadow: 0px 4.84631px 21.2026px rgba(0, 0, 0, 0.09);
  transform: scale(1.05);
}

.nes__dashboard__brok__client span {
  font-size: 14px;
}

.nes__dashboard__brok__client .text-muted {
  color: #8b9199 !important;
}

.nes__dashboard__brok__client__phone {
  width: 24px;
  height: 24px;
  background-color: #fc5c67;
  color: #ffffff;
  outline: none;
  border: none;
}

.nes__dashboard__brok__client__name {
  font-weight: 400;
  font-size: 18px !important;
  color: #000000;
}

.nes__dashboard__brok__client__name-title {
  font-size: 22px !important;
}

.nes__dashboard__brok__client__id {
  font-size: 16px !important;
}

.nes__dashboard__brok__client__phone__text {
  font-weight: 400;
  font-size: 16px !important;
  color: #000000;
}

.section-title {
  font-weight: 400;
  font-size: 24px;
  color: #000000;
}

.follow-up {
  color: #000000;
  background-color: #eaf5e7;
  border: 1.59418px solid #88c879;
  border-radius: 159.418px;
}

.client__Card__link {
  font-weight: 400;
  font-size: 14px;
  color: #278fd9;
}
