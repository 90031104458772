.about-project .heading {
  /* Define styles for the heading */
  font-size: 20px;
  font-weight: bold;
  /* Add any other styles as needed */
  color: brown;
}

.about-project .paragraph {
  /* Define styles for the paragraph */
  font-size: 16px;
  font-weight: normal;
  /* Add any other styles as needed */
  color: brown;
}
