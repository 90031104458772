.p-p_info-container {  
    overflow-y: hidden;
}

.p-p_info-container-2 {  
    overflow-y: hidden;
    margin-bottom: 2%;
}

.info_title-box {
    background: var(--lite-skyblue);
    padding: 14px 0;
    margin-top: 2%;
}

.info_title {
    font-weight: 600;
    font-size: 18px;
    color: var(--cold-black-color);
}

.info_span,
.info_span2 {
    font-weight: 400;
    font-size: 15px;
    display: block;
    line-height: 25px;
    color: var(--silver-surfer);
}

.info_span {
    margin-top: 10px;
    margin-bottom: 10px;
}