.shadow-card {
  box-shadow: 0px 6.34109px 27.7423px rgba(0, 0, 0, 0.07) !important  ;
}

.shadow-card:not(:first-child) {
  margin-top: 2.5rem;
}

.shadow-card .card-header {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  padding-bottom: 5px;
}
.shadow-card .card-body {
  font-weight: 400;
  font-size: 13px;
  color: #8ba0ae;
  padding-top: 0;
  color: #8ba0ae;
}
