.list-property {
    padding: 10px 0;
    text-align: center;
}

.list-property-div {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    padding: 10px 0;
}

.list-property-input {
    border: 1px solid #E8ECF4;
    background: #F8F8F8;
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    border-radius: 8px !important;
    margin-top: 4px;
}

.list-property-input:active,
.list-property-input:focus {
    outline: none;
    box-shadow: none;
}


input.list-property-input::placeholder {
    color: var(--cold-black-color) !important;
}

select.list-property-input {
    color: var(--gray-color) !important;
}

.list-property-btn {
    width: 100%;
    padding: 10px 0;
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 24px;
    background: var(--primary-color);
    color: var(--color-white) !important;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
}

/* Styling for labels */
.list-property-input-div {
    text-align: left;
    margin-bottom: 18px;
}
.list-property-input-label {
    color: var(--silver-surfer);
}