.reviews_slider {
    padding: 0 3%;
}
.reviews_slider .slick-next:before,
.slick-prev:before {
    /* font-size: 30px;
    color: var(--cold-black-color); */
}

.reviews_slider-row {
    padding: 0;
    margin: 0;
}

.reviews_slider .reviews_slider-col {
    width: 100%;
    padding: 35px 20px;
    border: 1px solid var(--divider);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reviews_slider .reviews_slider-userImg {
    margin: auto;
    width: 30%;
}

.reviews_slider .reviews_slider-box2 {
    margin-top: 1.55rem;
}

.reviews_slider .reviews_slider-span1 {
    line-height: 28px;
    font-weight: 400;
    color: var(--cold-black-color);
    font-size: 16px;
}

.reviews_slider .reviews_slider_link {
    text-decoration: none;
}

.reviews_slider .reviews_slider-span2 {
    line-height: 28px;
    font-weight: 400;
    color: var(--primary-color);
    cursor: pointer;
}

.reviews_slider .reviews_slider-box3 {
    margin-top: 1.55rem;
}

.reviews_slider .reviews_slider-span3 {
    color: var(--silver-surfer);
    font-weight: 400;
}
.reviews_slider-card{
    width: auto !important;
    display: block !important;
    max-width: 97.5%;
}